html,
body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden; }
  @media only screen and (max-width: 768px) {
    html,
    body {
      background: unset; } }

body {
  padding-top: 133px;
  background-color: #FFFFFF;
  /*@media only screen and (min-width: 992px) and (max-width: 1119px) {
        padding-top: 165px;
    }*/ }
  @media only screen and (max-width: 1200px) {
    body {
      padding-top: 76px; } }

.container {
  max-width: 1224px; }

/*#root {
    width: 100%;
    width: 100vw;
    overflow-x: hidden;
    >div {
        >div {
            width: 100%;
            float: left;
            overflow-x: hidden;
        }
    }
}*/
.text-bold {
  font-weight: 600; }

button {
  cursor: pointer; }

.form-container {
  padding: 0; }

.content-wrap {
  padding: 0; }

.breadcrumb {
  background-color: transparent;
  padding: 0;
  padding-bottom: 38px;
  margin: 0;
  float: right; }
  .breadcrumb > li {
    font-size: 16px;
    position: relative; }
    .breadcrumb > li::after {
      content: " / ";
      margin: 0 9px; }
    .breadcrumb > li a {
      color: #13287E; }
    .breadcrumb > li:last-child::after {
      content: '';
      display: none; }
    .breadcrumb > li:last-child a {
      color: #36C4F4; }
  @media only screen and (max-width: 768px) {
    .breadcrumb {
      display: none; } }

.section {
  padding: 20px 0;
  float: left;
  width: 100%; }
  @media only screen and (max-width: 768px) {
    .section {
      padding: 10px 0; } }
  .section h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 22px;
    padding-bottom: 5px; }
    .section h2 > span {
      color: #36C4F4; }
    @media only screen and (max-width: 768px) {
      .section h2 {
        font-size: 20px; } }
  .section .header-top {
    display: none; }
  .section .home-wrap .header {
    display: none; }
  .section .home-wrap .contact-phone {
    display: none; }

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 100vh;
  background-color: rgba(35, 38, 40, 0.9);
  z-index: 9999; }
  .lightbox .close {
    position: fixed;
    top: 16px;
    right: 16px;
    font-size: 48px;
    color: #FFFFFF;
    cursor: pointer;
    z-index: 99999; }
    .lightbox .close svg {
      fill: #FFFFFF; }
  .lightbox .lightbox-item {
    padding: 100px 200px;
    width: 100%;
    height: 100vh; }
    .lightbox .lightbox-item img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center; }
    .lightbox .lightbox-item .title {
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      padding-top: 16px; }
  .lightbox .carousel-control-prev-icon {
    width: 48px;
    height: 48px;
    cursor: pointer; }
  .lightbox .carousel-control-next-icon {
    width: 48px;
    height: 48px;
    cursor: pointer; }

.section-newsletter {
  background-color: #36C4F4;
  padding: 45px 0; }
  .section-newsletter .row > div {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 768px) {
      .section-newsletter .row > div {
        justify-content: center; } }
  .section-newsletter h3 {
    color: #FFFFFF;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0; }
  .section-newsletter p {
    color: #FFFFFF;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 300; }
    @media only screen and (max-width: 768px) {
      .section-newsletter p {
        margin-bottom: 16px; } }
  @media only screen and (max-width: 768px) {
    .section-newsletter > div {
      text-align: center; } }
  .section-newsletter input {
    border-radius: 134px;
    height: 50px;
    border: 1px solid #CCCCCC;
    outline: none;
    padding: 0 20px;
    width: 70%;
    font-size: 18px; }
    @media only screen and (max-width: 768px) {
      .section-newsletter input {
        width: 100%;
        font-size: 14px;
        width: 60%; } }
  .section-newsletter .button {
    height: 50px;
    background-color: #36C4F4;
    color: #FFFFFF;
    border-radius: 134px;
    border: 1px solid #FFFFFF;
    margin-left: 30px; }
    @media only screen and (max-width: 768px) {
      .section-newsletter .button {
        margin-left: 10px;
        font-size: 14px;
        padding: 8px 10px; } }

.section-articles a {
  text-decoration: none; }

.error-text {
  margin: 16px 0;
  color: #DB3838; }

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
  border-radius: rem-calc(5); }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #36C4F4;
  border-radius: rem-calc(5);
  cursor: pointer; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #36C4F4;
  cursor: pointer; }

.styled-numbers {
  margin-bottom: 20px;
  list-style: none; }
  .styled-numbers span {
    font-weight: 600;
    color: #36C4F4;
    margin-right: 8px; }

.text-primary {
  color: #36C4F4 !important; }

.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #FFFFFF;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05); }
  .cookies P {
    color: #13287E;
    margin-bottom: 0; }
  .cookies button {
    margin: 0 16px;
    border: 0;
    background-color: #13287E;
    color: #FFFFFF;
    padding: 6px 16px; }
    .cookies button:hover {
      background-color: #36C4F4; }
  .cookies a {
    color: #13287E;
    font-weight: 600;
    font-size: 18px; }
  @media only screen and (max-width: 768px) {
    .cookies {
      flex-direction: column;
      height: unset;
      padding: 32px 16px; }
      .cookies button {
        margin-top: 16px; } }

.pop-up-messages {
  position: fixed;
  right: 32px;
  top: 275px;
  z-index: 999999;
  width: 400px; }
  @media only screen and (max-width: 768px) {
    .pop-up-messages {
      width: 90%;
      margin-left: 5%;
      top: 0;
      margin-top: 50px;
      left: 0;
      right: unset; } }
  .pop-up-messages > div {
    padding: 42px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer; }
    .pop-up-messages > div .hide {
      color: #FFFFFF;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 22px;
      cursor: pointer;
      fill: #FFFFFF; }
    .pop-up-messages > div > p {
      color: #FFFFFF;
      display: flex;
      align-items: center;
      margin-bottom: 0; }
      .pop-up-messages > div > p::before {
        content: ' ';
        display: block;
        width: 10px;
        height: 20px;
        border-bottom: 3px solid #97ef9d;
        border-right: 3px solid #97ef9d;
        transform: rotate(45deg);
        float: left;
        margin-right: 16px; }
    .pop-up-messages > div .error::before {
      display: none; }
  .pop-up-messages .hide-message {
    animation-name: hide-message;
    animation-duration: 1s;
    transform: translate3d(110%, 0, 0); }

@keyframes hide-message {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(110%, 0, 0); } }
  .pop-up-messages .mdi-close {
    color: #DB3838;
    font-size: 24px;
    margin-right: 8px; }

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 800px;
  height: 520px;
  margin-left: -400px;
  margin-top: -260px;
  -webkit-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  z-index: 21;
  animation-name: rotate;
  animation-duration: 1s; }

@keyframes rotate {
  from {
    top: 0; }
  to {
    top: 50%; } }
  @media only screen and (max-width: 768px) {
    .popup {
      width: 90%;
      margin-left: 5%;
      left: 0;
      height: 400px; } }
  .popup .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .popup .content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding: 64px; }
    @media only screen and (max-width: 768px) {
      .popup .content {
        padding: 36px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; } }
    .popup .content .coupon {
      width: 425px;
      transform: rotate(-6deg);
      margin-bottom: 40px; }
      @media only screen and (max-width: 768px) {
        .popup .content .coupon {
          width: 70%; } }
      @media only screen and (max-width: 768px) {
        .popup .content .coupon {
          margin-bottom: 10px; } }
    .popup .content h2 {
      font-size: 46px;
      color: #FFFFFF; }
      @media only screen and (max-width: 768px) {
        .popup .content h2 {
          font-size: 24px; } }
    .popup .content h3 {
      font-size: 24px;
      color: #FFFFFF; }
      @media only screen and (max-width: 768px) {
        .popup .content h3 {
          font-size: 18px; } }
    .popup .content h6 {
      font-size: 15px;
      color: #CCCCCC;
      margin-bottom: 20px; }
      @media only screen and (max-width: 768px) {
        .popup .content h6 {
          font-size: 12px; } }
    .popup .content .button {
      position: absolute;
      bottom: 120px;
      left: 50%;
      margin-left: -120px;
      background-color: #FFF323; }

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 19;
  background-color: rgba(0, 0, 0, 0.3); }

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px; }

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #36C4F4;
  border-color: #36C4F4 transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite; }

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.not-found-content {
  text-align: center;
  padding: 38px 0; }
  .not-found-content h1 {
    font-size: 100px !important; }
  .not-found-content h2 {
    font-size: 32px !important;
    margin-bottom: 32px; }

article {
  position: relative; }
  article .discount {
    position: absolute;
    left: 20px;
    top: 20px;
    background-color: #36C4F4;
    color: #FFFFFF;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media only screen and (max-width: 768px) {
      article .discount {
        right: 0px; } }

.list-article .discount {
  right: 20px;
  left: unset; }

.discount-detail {
  position: absolute;
  left: 35px;
  top: 20px;
  background-color: #B3DE5B;
  background-color: #36C4F4;
  color: #FFFFFF;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 18px;
  z-index: 19;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media only screen and (max-width: 768px) {
    .discount-detail {
      left: 50px; } }

.news-detail {
  margin-top: 124px; }
  .news-detail h1 {
    margin-bottom: 24px;
    font-size: 36px;
    font-weight: 700;
    color: #13287E; }
  .news-detail P {
    font-size: 16px;
    color: #13287E; }
  .news-detail .date {
    color: #36C4F4;
    font-size: 14px; }
  .news-detail img {
    width: 100%;
    margin-bottom: 16px;
    border-radius: 20px; }

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  margin-top: 16px; }

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #36C4F4;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #36C4F4 transparent transparent transparent; }

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s; }

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s; }

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.section-banners {
  position: relative; }
  .section-banners img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    margin-bottom: 15px;
    border-radius: 20px; }
  .section-banners .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%; }
    .section-banners .content h6 {
      color: #FFFFFF;
      font-size: 32px;
      font-weight: 700; }
    .section-banners .content button {
      background-color: transparent;
      border: 1px solid #FFFFFF;
      border-radius: 119px;
      color: #FFFFFF;
      padding: 7px 20px; }

.categories {
  background-color: #FFFFFF;
  float: left;
  width: 100%;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; }
  .categories .nav {
    margin-top: 5px; }
    .categories .nav ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%; }
      .categories .nav ul li {
        border: 1px dashed #D2D2D2;
        border-radius: 10px;
        padding: 12px 15px;
        margin-bottom: 15px; }
        .categories .nav ul li a {
          color: #13287E;
          font-size: 16px;
          font-weight: 600; }
  .categories h3 {
    background-color: #36C4F4;
    color: #FFFFFF;
    font-size: 19px;
    font-weight: 600;
    padding: 15px 16px;
    border-radius: 10px 10px 0 0px;
    margin-bottom: 0; }
  .categories > ul {
    border-radius: 0 0 10px 10px;
    width: 100%;
    list-style: none;
    padding: 16px 16px;
    margin: 0;
    border-top: none;
    float: left; }
    .categories > ul > li {
      width: 100%;
      float: left;
      /*&::after {
                content: " ";
                display: block;
                border-top: 2px solid $gray;
                border-right: 2px solid $gray;
                width: 10px;
                height: 10px;
                transform: rotate(45deg);
                float: right;
                margin-top: 16px;
                margin-right: 4px;
            }*/ }
      .categories > ul > li:last-child {
        border-bottom: none; }
      .categories > ul > li a {
        float: left;
        color: #13287E;
        padding: 7px 0; }
        .categories > ul > li a .isvg {
          margin-right: 12px; }
      .categories > ul > li > a {
        font-size: 16px;
        font-weight: 500; }
      .categories > ul > li:hover > a {
        font-weight: 600;
        color: #36C4F4; }
      .categories > ul > li ul {
        width: 100%;
        float: left;
        list-style: none;
        padding-left: 60px;
        position: relative; }
        .categories > ul > li ul::after {
          content: " ";
          display: block;
          border-bottom: 2px solid #36C4F4;
          border-left: 2px solid #36C4F4;
          width: 12px;
          height: 12px;
          margin-top: 11px;
          position: absolute;
          top: 0;
          left: 40px; }
        .categories > ul > li ul li {
          width: 100%;
          float: left; }
    .categories > ul .active {
      position: relative; }
      .categories > ul .active > a {
        font-weight: 600;
        color: #36C4F4; }
      .categories > ul .active::after {
        position: absolute;
        top: 0;
        right: 0;
        transform: rotate(135deg); }

.map {
  height: 600px;
  width: 100%; }
  @media only screen and (max-width: 1200px) {
    .map {
      height: 350px; } }

.page-top-section {
  background-color: #D9EEF9;
  padding: 68px 0; }
  @media only screen and (max-width: 1200px) {
    .page-top-section {
      padding: 20px 0; } }
  .page-top-section h1 {
    font-size: 46px;
    font-weight: 700;
    color: #13287E;
    margin-bottom: 0; }
    @media only screen and (max-width: 1200px) {
      .page-top-section h1 {
        font-size: 32px; } }
  .page-top-section .row {
    align-items: center; }
  .page-top-section ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    justify-content: flex-end; }
    @media only screen and (max-width: 1200px) {
      .page-top-section ul {
        display: none; } }
    .page-top-section ul li {
      font-size: 16px;
      color: #36C4F4; }
      .page-top-section ul li a {
        color: black; }
        .page-top-section ul li a::after {
          content: '/';
          margin: 0 5px;
          color: #36C4F4; }

.about-section {
  padding: 54px 0;
  padding-bottom: 0; }
  .about-section h3 {
    color: #13287E;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 14px; }
  .about-section p {
    font-size: 16px;
    color: #13287E; }
    .about-section p strong {
      font-weight: 600; }
  .about-section img {
    margin-bottom: 100px;
    width: 100%; }

.aboutus-info-section {
  background-color: #D9EEF9;
  padding-top: 70px;
  padding-bottom: 90px;
  margin-top: 30px; }
  .aboutus-info-section .blue-block {
    background-color: #36C4F4;
    border-radius: 20px;
    padding: 40px 30px;
    text-align: center; }
    .aboutus-info-section .blue-block p {
      margin-top: 30px;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 600; }
    @media only screen and (max-width: 1200px) {
      .aboutus-info-section .blue-block {
        margin-bottom: 30px; } }
  .aboutus-info-section .block {
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 40px 30px;
    text-align: center;
    /* Set the sizes of the elements that make up the progress bar */
    /* Using the data attributes for the animation selectors. */
    /* Base settings for all animated elements */
    /* Rotate the right side of the progress bar from 0 to 180 degrees */
    /* Rotate the left side of the progress bar from 0 to 360 degrees */
    /* Rotate the left side of the progress bar from 0 to 360 degrees */
    /* Rotate the left side of the progress bar from 0 to 360 degrees */
    /* Set the wrapper clip to auto, effectively removing the clip */ }
    @media only screen and (max-width: 1200px) {
      .aboutus-info-section .block {
        margin-bottom: 30px; } }
    .aboutus-info-section .block h6 {
      color: #13287E;
      margin-top: 20px;
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 9px; }
    .aboutus-info-section .block .progress {
      margin: 0 auto;
      position: relative;
      width: 140px;
      /* Set the size of the progress bar */
      height: 140px;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center; }
      .aboutus-info-section .block .progress p {
        font-size: 36px;
        font-weight: 700;
        color: #13287E;
        margin-bottom: 0; }
    .aboutus-info-section .block .wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 140px;
      /* Set the size of the progress bar */
      height: 140px;
      position: absolute;
      /* Enable clipping */
      clip: rect(0px, 140px, 140px, 70px);
      /* Hide half of the progress bar */
      color: unset;
      background-color: transparent; }
    .aboutus-info-section .block .circle {
      width: 140px;
      height: 140px;
      border: 8px solid #36C4F4;
      border-radius: 70px;
      position: absolute;
      clip: rect(0px, 70px, 140px, 0px); }
    .aboutus-info-section .block div[data-anim~=base] {
      -webkit-animation-iteration-count: 1;
      /* Only run once */
      -webkit-animation-fill-mode: forwards;
      /* Hold the last keyframe */
      -webkit-animation-timing-function: linear;
      /* Linear animation */ }
    .aboutus-info-section .block .wrapper[data-anim~=wrapper] {
      -webkit-animation-duration: 0.01s;
      /* Complete keyframes asap */
      -webkit-animation-delay: 0.5s;
      /* Wait half of the animation */
      -webkit-animation-name: close-wrapper;
      /* Keyframes name */ }
    .aboutus-info-section .block .circle[data-anim~=left] {
      -webkit-animation-duration: 1s;
      /* Full animation time */
      -webkit-animation-name: left-spin; }
    .aboutus-info-section .block .circle[data-anim~=left1] {
      -webkit-animation-duration: 1s;
      /* Full animation time */
      -webkit-animation-name: left-spin1; }
    .aboutus-info-section .block .circle[data-anim~=left2] {
      -webkit-animation-duration: 1s;
      /* Full animation time */
      -webkit-animation-name: left-spin2; }
    .aboutus-info-section .block .circle[data-anim~=right] {
      -webkit-animation-duration: 0.5s;
      /* Half animation time */
      -webkit-animation-name: right-spin; }

@-webkit-keyframes right-spin {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(180deg); } }

@-webkit-keyframes left-spin {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@-webkit-keyframes left-spin1 {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(340deg); } }

@-webkit-keyframes left-spin2 {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(320deg); } }

@-webkit-keyframes close-wrapper {
  to {
    clip: rect(auto, auto, auto, auto); } }

.aboutus-info-section-1 {
  padding: 90px 0; }
  .aboutus-info-section-1 h3 {
    font-size: 32px;
    font-weight: 700;
    color: #13287E; }
    .aboutus-info-section-1 h3 span {
      color: #36C4F4; }
  .aboutus-info-section-1 p {
    font-size: 16px;
    color: #13287E; }
  .aboutus-info-section-1 img {
    width: 100%; }
  .aboutus-info-section-1 .wrap {
    display: flex;
    justify-content: space-between; }
    .aboutus-info-section-1 .wrap img {
      width: 48%;
      float: left;
      height: 255px;
      object-fit: cover;
      border-radius: 20px; }
    .aboutus-info-section-1 .wrap .block {
      width: 48%;
      float: left; }
  .aboutus-info-section-1 .block {
    background-color: #36C4F4;
    border-radius: 20px;
    padding: 40px 30px; }
    @media only screen and (max-width: 1200px) {
      .aboutus-info-section-1 .block {
        margin-bottom: 30px; } }
    .aboutus-info-section-1 .block .icon {
      display: flex;
      align-items: center; }
      .aboutus-info-section-1 .block .icon::after {
        content: ' ';
        display: block;
        height: 2px;
        width: 65px;
        background-color: #FFFFFF;
        margin-left: 15px; }
    .aboutus-info-section-1 .block p {
      color: #FFFFFF;
      font-weight: 300;
      margin-bottom: 8px; }
    .aboutus-info-section-1 .block .tel {
      color: #FFFFFF;
      font-size: 24px;
      font-weight: 600; }
    .aboutus-info-section-1 .block .button {
      margin-top: 31px;
      background-color: #FFFFFF;
      color: #13287E;
      font-size: 16px;
      font-weight: 600;
      padding: 12px 25px;
      border-radius: 27px;
      width: 100%; }

.service-info-section .blue-block {
  background-color: #36C4F4;
  border-radius: 20px;
  padding: 40px 30px;
  text-align: center; }
  .service-info-section .blue-block p {
    margin-top: 30px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600; }

@media only screen and (max-width: 1200px) {
  .service-info-section .col-lg-4 {
    margin-bottom: 30px; } }

.service-info-section .block {
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 40px 30px;
  text-align: center;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
  height: 100%; }
  .service-info-section .block p {
    margin-top: 30px;
    color: #13287E; }
  .service-info-section .block h6 {
    color: #13287E;
    margin-top: 20px;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 9px; }
  .service-info-section .block .progress {
    margin: 0 auto;
    position: relative;
    width: 140px;
    /* Set the size of the progress bar */
    height: 140px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center; }
    .service-info-section .block .progress p {
      font-size: 36px;
      font-weight: 700;
      color: #13287E;
      margin-bottom: 0; }

.service-info-section .blue-wrap {
  background-color: #36C4F4;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 600;
  border-radius: 20px;
  margin-top: 100px; }
  .service-info-section .blue-wrap p {
    padding: 20px 30px;
    margin-bottom: 0; }

.service-section img {
  margin-bottom: 0; }

.service-section .padding-text {
  padding-top: 50px; }

.contact-section {
  padding-top: 74px;
  padding-bottom: 70px; }
  @media only screen and (max-width: 1200px) {
    .contact-section {
      padding-top: 32px; } }
  .contact-section .left h3 {
    font-size: 26px;
    font-weight: 700;
    color: #13287E; }
  .contact-section .left p {
    color: #13287E; }
  .contact-section .left h6 {
    font-size: 20px;
    font-weight: 700;
    color: #13287E;
    margin-bottom: 17px; }
  .contact-section .left .spacer {
    height: 1px;
    width: 100%;
    background-color: #E4E4E4;
    margin: 20px 0; }
  .contact-section .left .item {
    width: 100%;
    display: flex;
    margin-bottom: 16px; }
    .contact-section .left .item > div {
      margin-left: 20px; }
      .contact-section .left .item > div p {
        margin-bottom: 0; }
        .contact-section .left .item > div p:nth-child(2) {
          font-weight: 700; }
  .contact-section .right {
    background-color: #36C4F4;
    border-radius: 20px;
    padding: 30px; }
    .contact-section .right h3 {
      font-size: 26px;
      font-weight: 700;
      color: #FFFFFF; }
    .contact-section .right input, .contact-section .right textarea {
      max-width: unset !important;
      width: 100% !important;
      border-radius: 30px !important; }
    .contact-section .right label {
      margin-bottom: 0; }
    .contact-section .right .input-wrap {
      margin-bottom: 10px; }
    .contact-section .right textarea {
      padding-top: 20px; }
    .contact-section .right button {
      background-color: #13287E;
      border-radius: 25px;
      padding: 12px 50px;
      font-size: 18px;
      font-weight: 600; }
  .contact-section .map {
    margin-top: 50px; }

.contact-block {
  background-color: #36C4F4;
  border-radius: 10px;
  padding: 40px 30px;
  margin-top: 30px;
  float: left; }
  .contact-block .icon {
    display: flex;
    align-items: center; }
    .contact-block .icon::after {
      content: ' ';
      display: block;
      height: 2px;
      width: 65px;
      background-color: #FFFFFF;
      margin-left: 15px; }
  .contact-block p {
    color: #FFFFFF;
    font-weight: 300;
    margin-bottom: 8px; }
  .contact-block .tel {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 600; }
  .contact-block .button {
    margin-top: 31px;
    background-color: #FFFFFF;
    color: #13287E;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 25px;
    border-radius: 27px;
    width: 100%; }

.latest-news {
  margin-top: 65px;
  margin-bottom: 100px; }
  .latest-news h3 {
    font-size: 26px;
    font-weight: 700;
    color: #13287E;
    margin-bottom: 23px; }
  .latest-news article {
    margin-bottom: 30px;
    text-align: left;
    -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    padding-bottom: 24px;
    border-radius: 20px; }
    @media only screen and (max-width: 1200px) {
      .latest-news article {
        margin-bottom: 15px; } }
    .latest-news article img {
      width: 100%;
      height: 200px;
      margin-bottom: 18px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px; }
    .latest-news article h6 {
      font-size: 20px;
      font-weight: 700;
      color: #13287E;
      margin-bottom: 9px;
      padding: 0 20px; }
    .latest-news article P {
      font-size: 16px;
      color: #13287E;
      padding: 0 20px; }
    .latest-news article .date {
      color: #36C4F4;
      font-size: 14px; }

.application-view .account-wrap, .application-view .category-wrap {
  padding-top: 0 !important; }

.application-view .home-wrap .section-articles {
  padding-top: 20px !important; }
  .application-view .home-wrap .section-articles .btn-wrap {
    padding-bottom: 40px; }
    .application-view .home-wrap .section-articles .btn-wrap .button {
      padding: 8px 20px; }

.application-view .home-wrap .home-brands-image {
  width: 100%;
  height: 180px;
  object-fit: cover; }

.find-client-wrap {
  margin-bottom: 32px;
  position: relative; }
  .find-client-wrap ul {
    position: absolute;
    top: 92px;
    background-color: #FFFFFF;
    width: 100%;
    padding: 32px 16px;
    list-style: none;
    z-index: 9999;
    border: 1px solid #D2D2D2; }
    .find-client-wrap ul > li {
      padding: 8px 16px; }
      .find-client-wrap ul > li:hover {
        background-color: #F5F5F5;
        cursor: pointer; }

.delete-client {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }
  .delete-client .isvg {
    cursor: pointer; }

.deliveryBox-container {
  border: 1px solid #36C4F4;
  margin-bottom: 32px; }
  .deliveryBox-container p {
    margin-bottom: 0; }
  .deliveryBox-container .text {
    padding: 15px; }

.deliveryBox {
  background: #36C4F4;
  color: white;
  padding: 8px 16px; }
  .deliveryBox h6 {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0; }

.button {
  color: #FFFFFF;
  background-color: #13287E;
  border-radius: 6px;
  padding: 8px 28px;
  text-decoration: none;
  outline: none;
  border: none; }
  .button .isvg {
    margin-right: 8px; }

.button-black {
  background-color: #13287E; }

.button-right {
  float: right; }

.big-button {
  height: 50px; }

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  background-color: #FFFFFF; }
  header .header-top {
    width: 100%;
    background-color: #36C4F4;
    color: #FFFFFF;
    padding: 8px 0;
    font-size: 14px;
    font-weight: 400s; }
    @media only screen and (max-width: 1199px) {
      header .header-top {
        display: none; } }
    header .header-top .header-top-container {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    header .header-top .info {
      color: #FFFFFF;
      font-size: 14px; }
      header .header-top .info p {
        margin-bottom: 0; }
      header .header-top .info a {
        font-weight: 600;
        color: #FFFFFF; }
    header .header-top .social {
      text-align: right;
      font-size: 14px;
      color: #FFFFFF; }
      header .header-top .social .isvg {
        margin: 0 10px; }
        header .header-top .social .isvg svg {
          fill: #FFFFFF; }
      header .header-top .social .currency {
        float: right;
        display: flex;
        align-items: center;
        justify-content: center; }
        header .header-top .social .currency a {
          color: #FFFFFF;
          margin: 0 5px; }
    header .header-top .actions {
      text-align: left; }
      header .header-top .actions a {
        margin-right: 32px;
        color: #FFFFFF; }
        header .header-top .actions a .isvg {
          margin-right: 10px; }
        header .header-top .actions a svg {
          fill: #FFFFFF; }
        header .header-top .actions a:hover {
          color: #FFFFFF;
          text-decoration: none; }
          header .header-top .actions a:hover svg {
            fill: #FFFFFF; }
    header .header-top .contact {
      display: flex;
      font-size: 15px;
      color: #FFFFFF; }
      header .header-top .contact > div {
        margin: 0 10px; }
    header .header-top .right-area {
      display: flex; }
      header .header-top .right-area .lang {
        color: #13287E; }
        header .header-top .right-area .lang img {
          margin-right: 8px; }
        header .header-top .right-area .lang .select-field {
          border: 0;
          background-color: transparent; }
          header .header-top .right-area .lang .select-field > a {
            padding: 0; }
          header .header-top .right-area .lang .select-field .dropdown-toggle::before {
            margin-left: 10px; }
      header .header-top .right-area .account {
        margin-left: 40px; }
        header .header-top .right-area .account a {
          color: #13287E; }
  header .navigation-container {
    background-color: #FFFFFF; }
    header .navigation-container .category {
      background-color: #36C4F4;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer; }
  header .header {
    padding-top: 8px;
    padding-bottom: 14px; }
    @media only screen and (max-width: 1200px) {
      header .header {
        padding-top: 18px;
        padding-bottom: 18px; } }
    header .header > .row {
      align-items: center; }
    header .header .mobile-hamburger {
      display: none; }
      @media only screen and (max-width: 1200px) {
        header .header .mobile-hamburger {
          padding: 0;
          display: flex;
          align-items: center; }
          header .header .mobile-hamburger svg {
            fill: #36C4F4; } }
    header .header .mobile-actions {
      padding-right: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media only screen and (min-width: 1200px) {
        header .header .mobile-actions {
          display: none; } }
      header .header .mobile-actions .search-icon svg path {
        fill: #13287E; }
      header .header .mobile-actions button {
        background-color: transparent;
        outline: none;
        border: 0;
        margin: 0 3px; }
      header .header .mobile-actions svg {
        fill: #13287E; }
      header .header .mobile-actions a {
        margin: 0 3px;
        position: relative; }
        header .header .mobile-actions a svg {
          fill: none;
          stroke: #13287E; }
        header .header .mobile-actions a .cart-count {
          position: absolute;
          top: -10px;
          right: -10px;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background-color: #36C4F4;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 500;
          color: #FFFFFF !important; }
    header .header .hamburger {
      display: none; }
      @media only screen and (max-width: 1200px) {
        header .header .hamburger {
          display: flex !important;
          align-items: center; }
          header .header .hamburger svg {
            fill: #13287E; } }
    header .header .logo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative; }
      @media only screen and (max-width: 1200px) {
        header .header .logo {
          padding-left: 0; } }
      header .header .logo svg {
        max-height: 100px; }
        @media only screen and (max-width: 1200px) {
          header .header .logo svg {
            width: 100%; } }
      header .header .logo .mark {
        background-color: transparent;
        z-index: 2; }
        @media only screen and (max-width: 1200px) {
          header .header .logo .mark {
            display: none; } }
    header .header .search-form {
      position: relative;
      display: flex !important;
      align-items: center;
      border-radius: 121px;
      background-color: #FFFFFF;
      height: 54px; }
      @media only screen and (max-width: 1200px) {
        header .header .search-form {
          display: none !important; } }
      header .header .search-form input {
        border-radius: 121px;
        border: 0;
        background-color: #FFFFFF;
        width: 100%;
        padding: 13px 60px 13px 20px;
        outline: none;
        color: #8E8E8E;
        font-size: 14px; }
        @media only screen and (max-width: 1200px) {
          header .header .search-form input {
            height: 37px;
            padding: 0 20px 0 20px; } }
      header .header .search-form .mdi-magnify {
        font-size: 24px;
        position: absolute;
        right: 20px; }
      header .header .search-form button {
        background-color: #36C4F4;
        border-radius: 121px;
        border: none;
        height: 46px;
        width: 46px;
        position: absolute;
        right: 5px;
        color: #FFFFFF;
        padding: 0; }
        @media only screen and (max-width: 1200px) {
          header .header .search-form button {
            display: none; } }
      header .header .search-form .search-results {
        position: absolute;
        top: 40px;
        width: calc(100% - 89px);
        background-color: #FFFFFF;
        padding: 16px 0;
        z-index: 9;
        -webkit-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1); }
        @media only screen and (max-width: 1200px) {
          header .header .search-form .search-results {
            top: 40px;
            width: 300px; } }
        header .header .search-form .search-results h6 {
          font-size: 20px;
          padding: 0 16px; }
        header .header .search-form .search-results ul {
          padding: 0;
          list-style: none; }
          header .header .search-form .search-results ul li {
            padding: 8px 16px;
            cursor: pointer;
            float: left;
            width: 100%; }
            @media only screen and (max-width: 1200px) {
              header .header .search-form .search-results ul li {
                padding: 8px 4px;
                font-size: 12px; } }
            header .header .search-form .search-results ul li img {
              width: 60px;
              margin-right: 16px;
              height: 60px;
              object-fit: contain; }
              @media only screen and (max-width: 1200px) {
                header .header .search-form .search-results ul li img {
                  width: 24px;
                  height: 24px;
                  margin-right: 6px; } }
            header .header .search-form .search-results ul li a {
              color: #13287E;
              width: 100%;
              height: 100%;
              float: left;
              text-decoration: none; }
            header .header .search-form .search-results ul li:hover {
              background-color: #eeeeee; }
    header .header .account {
      display: flex !important;
      align-items: center;
      justify-content: space-between; }
      @media only screen and (max-width: 1200px) {
        header .header .account {
          color: transparent;
          display: none !important; } }
      header .header .account .isvg {
        margin-right: 5px; }
        @media only screen and (max-width: 1200px) {
          header .header .account .isvg {
            margin-right: 0;
            margin-left: 0; } }
        header .header .account .isvg svg {
          fill: #FFFFFF; }
      header .header .account a {
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 600;
        margin: 0 5px;
        text-decoration: none; }
        @media only screen and (max-width: 1200px) {
          header .header .account a {
            display: none; } }
      header .header .account .account-text {
        display: flex;
        flex-direction: column; }
        header .header .account .account-text a {
          font-size: 14px; }
          header .header .account .account-text a:nth-child(2) {
            font-size: 14px;
            font-weight: 400; }
      header .header .account .account-button {
        display: flex;
        align-items: center;
        font-size: 14px; }
      header .header .account .cart-button {
        position: relative;
        float: left;
        height: 50px;
        border-radius: 119px;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 18px;
        padding-left: 25px;
        float: right;
        margin: 0; }
        header .header .account .cart-button svg path {
          stroke: #13287E; }
        @media only screen and (max-width: 1200px) {
          header .header .account .cart-button {
            background-color: #FFFFFF;
            height: 37px;
            padding: 0; } }
        header .header .account .cart-button .cart-text {
          margin-left: 10px;
          position: relative; }
          header .header .account .cart-button .cart-text p {
            color: #36C4F4;
            margin-bottom: 0;
            font-size: 15px;
            font-weight: 500; }
            header .header .account .cart-button .cart-text p:nth-child(3) {
              color: #13287E;
              font-weight: 400; }
          header .header .account .cart-button .cart-text .count {
            background-color: #36C4F4;
            border-radius: 10px;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            position: absolute;
            left: -55px;
            top: 3px;
            width: 20px;
            height: 20px; }
        header .header .account .cart-button .number {
          position: absolute;
          top: 6px;
          right: 8px;
          border-radius: 50%;
          background-color: #FFFFFF;
          color: #13287E;
          width: 20px;
          height: 20px;
          font-size: 14px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center; }
          @media only screen and (max-width: 1200px) {
            header .header .account .cart-button .number {
              display: none; } }
        header .header .account .cart-button svg {
          fill: none;
          stroke: #FFFFFF;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 2px; }
          @media only screen and (max-width: 1200px) {
            header .header .account .cart-button svg {
              stroke: #13287E; } }
      header .header .account .wishlist-button {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #13287E;
        font-size: 15px; }
        header .header .account .wishlist-button span {
          margin-left: 10px; }
        header .header .account .wishlist-button svg path {
          stroke: #13287E; }
    header .header .cart {
      display: flex;
      align-items: center; }
      header .header .cart .cart-button {
        position: relative;
        float: left;
        width: 100%;
        height: 50px;
        border-radius: 6px;
        background-color: transparent;
        border: 1px solid #CCCCCC;
        display: flex;
        align-items: center;
        padding: 0 10px; }
        @media only screen and (max-width: 1200px) {
          header .header .cart .cart-button {
            background-color: #FFFFFF;
            height: 37px;
            padding: 0; } }
        header .header .cart .cart-button .number {
          position: absolute;
          top: 6px;
          right: 8px;
          border-radius: 50%;
          background-color: #FFFFFF;
          color: #13287E;
          width: 20px;
          height: 20px;
          font-size: 14px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center; }
          @media only screen and (max-width: 1200px) {
            header .header .cart .cart-button .number {
              display: none; } }
        header .header .cart .cart-button svg {
          fill: none;
          stroke: #FFFFFF;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 2px; }
          @media only screen and (max-width: 1200px) {
            header .header .cart .cart-button svg {
              stroke: #13287E; } }
    header .header .cart-button-mobile {
      position: relative;
      float: left;
      width: 100%;
      height: 50px;
      background-color: transparent;
      display: flex;
      align-items: center;
      padding: 0 10px; }
      @media only screen and (max-width: 1200px) {
        header .header .cart-button-mobile {
          height: 37px;
          padding: 0; } }
      header .header .cart-button-mobile .number {
        position: absolute;
        top: 6px;
        right: 8px;
        border-radius: 50%;
        background-color: #FFFFFF;
        color: #13287E;
        width: 20px;
        height: 20px;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center; }
        @media only screen and (max-width: 1200px) {
          header .header .cart-button-mobile .number {
            display: none; } }
      header .header .cart-button-mobile svg {
        fill: none;
        stroke: #FFFFFF;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px; }
        @media only screen and (max-width: 1200px) {
          header .header .cart-button-mobile svg {
            stroke: #FFFFFF; } }
  header .spacer {
    width: 100%;
    height: 1px;
    background-color: #CCCCCC; }
  header .navigation {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0; }
    @media only screen and (max-width: 1200px) {
      header .navigation {
        display: none; } }
    header .navigation li a {
      float: left;
      color: #2E2E3B;
      font-size: 16px;
      font-weight: 600;
      padding: 14px 0;
      text-decoration: none;
      padding: 6px 16px; }
    header .navigation li:last-child {
      background-color: #FFF323;
      border-radius: 119px;
      margin-left: 42px; }
    header .navigation .active {
      color: #FFFFFF;
      background-color: #36C4F4;
      border-radius: 119px; }
  header .contact-phone {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    color: #FFFFFF; }
    @media only screen and (max-width: 1119px) {
      header .contact-phone {
        display: none; } }
    header .contact-phone .isvg {
      margin-right: 8px; }

.mobile-left-menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(-110%, 0, 0);
  -moz-transform: translate3d(-110%, 0, 0);
  transform: translate3d(-110%, 0, 0); }
  .mobile-left-menu .content {
    width: 300px;
    height: 100%;
    background-color: #FFFFFF;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
    -moz-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0);
    -webkit-transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    -moz-transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    cursor: pointer; }
    .mobile-left-menu .content .user-area {
      width: 100%;
      height: 160px;
      background-color: #36C4F4;
      padding: 30px 30px;
      position: relative; }
      .mobile-left-menu .content .user-area .currency-mobile {
        position: absolute;
        top: 16px;
        right: 16px; }
        .mobile-left-menu .content .user-area .currency-mobile a {
          margin: 0 5px;
          color: #FFFFFF; }
      .mobile-left-menu .content .user-area .user-image {
        width: 64px;
        height: 64px;
        background-color: rgba(255, 255, 255, 0.5);
        margin-bottom: 10px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center; }
        .mobile-left-menu .content .user-area .user-image svg path {
          stroke: #FFFFFF; }
      .mobile-left-menu .content .user-area p {
        color: #FFFFFF;
        margin-bottom: 0;
        font-size: 14px; }
        .mobile-left-menu .content .user-area p:nth-child(2) {
          font-weight: 600; }
        .mobile-left-menu .content .user-area p a {
          color: #FFFFFF; }
    .mobile-left-menu .content ul {
      padding: 25px;
      list-style: none; }
      .mobile-left-menu .content ul > li {
        padding: 8px 0;
        font-weight: 600;
        color: #13287E; }
        .mobile-left-menu .content ul > li a {
          color: #13287E;
          display: flex;
          align-items: center; }
        .mobile-left-menu .content ul > li svg {
          height: 24px;
          width: 24px;
          margin-right: 10px; }
        .mobile-left-menu .content ul > li i {
          font-size: 24px;
          margin-right: 10px; }

.mobile-left-menu-open {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
  .mobile-left-menu-open .content {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

.mobile-right-menu {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(110%, 0, 0);
  -moz-transform: translate3d(110%, 0, 0);
  transform: translate3d(110%, 0, 0);
  cursor: pointer; }
  .mobile-right-menu .content {
    width: 300px;
    height: 100%;
    float: right;
    background-color: #FFFFFF;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
    -moz-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    -webkit-transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    -moz-transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    position: relative; }
    .mobile-right-menu .content h3 {
      background-color: #36C4F4;
      color: #FFFFFF;
      padding: 15px 22px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0; }
    .mobile-right-menu .content .close {
      position: absolute;
      top: 6px;
      right: 6px;
      color: #FFFFFF;
      font-size: 32px;
      fill: #FFFFFF; }
    .mobile-right-menu .content > ul {
      list-style: none;
      padding: 20px 16px;
      margin: 0;
      border-top: none;
      float: left;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding-bottom: 100px; }
      .mobile-right-menu .content > ul > li {
        border-bottom: 1px solid #CCCCCC;
        width: 100%;
        float: left; }
        .mobile-right-menu .content > ul > li::after {
          /* content: " ";
                    display: block;
                    border-top: 2px solid $gray;
                    border-right: 2px solid $gray;
                    width: 10px;
                    height: 10px;
                    transform: rotate(45deg);
                    float: right;
                    margin-top: 16px;
                    margin-right: 4px;*/ }
        .mobile-right-menu .content > ul > li a {
          float: left;
          color: #13287E;
          padding: 7px 0; }
        .mobile-right-menu .content > ul > li > a {
          font-size: 18px; }
        .mobile-right-menu .content > ul > li ul {
          width: 100%;
          float: left;
          list-style: none;
          padding-left: 14px;
          position: relative; }
          .mobile-right-menu .content > ul > li ul::after {
            content: " ";
            display: block;
            border-bottom: 2px solid #36C4F4;
            border-left: 2px solid #36C4F4;
            width: 8px;
            height: 8px;
            margin-top: 14px;
            position: absolute;
            top: 0;
            left: 0; }
          .mobile-right-menu .content > ul > li ul li {
            width: 100%;
            float: left; }
      .mobile-right-menu .content > ul .active {
        position: relative; }
        .mobile-right-menu .content > ul .active::after {
          position: absolute;
          top: 0;
          right: 0;
          transform: rotate(135deg); }

.mobile-right-menu-open {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
  .mobile-right-menu-open .content {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

/*
@include mQ(767px, 320px) {
    .header-top {
        display: none;
    }
    .home-wrap {
        .header {
            display: none;
        }
        .contact-phone {
            display: none;
        }
    }
    .navigation {
        display: none;
    }
}

@include mQ(1024px, 768px) {
    .header-top {
        display: none;
    }
    .home-wrap {
        .header {
            display: none;
        }
        .contact-phone {
            display: none;
        }
    }
    .navigation {
        display: none;
    }
}*/
.search-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #36C4F4; }
  .search-container .input {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    margin: 0 auto;
    width: 100%;
    margin-top: -15px; }
    .search-container .input button {
      background-color: transparent;
      border: 0;
      outline: none; }
    .search-container .input input {
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid #FFFFFF;
      color: #FFFFFF;
      outline: none; }
    .search-container .input ::-webkit-input-placeholder {
      /* Edge */
      color: #FFFFFF; }
    .search-container .input :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #FFFFFF; }
    .search-container .input ::placeholder {
      color: #FFFFFF; }
  .search-container .close {
    position: absolute;
    right: 16px;
    padding: 16px;
    font-size: 32px;
    background-color: transparent;
    border: 0;
    outline: none; }
    .search-container .close svg {
      fill: #FFFFFF; }
    .search-container .close i {
      color: #FFFFFF; }

.categories-dropdown {
  position: relative; }
  .categories-dropdown .categories {
    position: absolute;
    top: 100%;
    margin-top: 2px;
    width: calc(100% - 30px); }

.shop-header {
  background-color: #D9EEF9;
  padding: 28px 0 !important; }
  @media only screen and (max-width: 1200px) {
    .shop-header {
      padding: 20px 0 !important; } }
  .shop-header h1 {
    font-size: 46px;
    font-weight: 700;
    color: #13287E;
    margin-bottom: 0; }
    @media only screen and (max-width: 1200px) {
      .shop-header h1 {
        font-size: 26px; } }

.app-header {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16); }

.footer {
  position: relative;
  overflow: hidden;
  padding-bottom: 16px;
  padding-top: 30px;
  width: 100%;
  float: left;
  background-color: #13287E; }
  .footer h6 {
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    width: 100%;
    position: relative;
    margin-bottom: 40px; }
  .footer .telephone {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600; }
  .footer p {
    color: #D9EEF9;
    font-weight: 300;
    font-size: 14px; }
  .footer .logo {
    width: 176px;
    margin-bottom: 21px; }
    .footer .logo > img {
      width: 100%; }
  .footer .spacer {
    width: 100%;
    float: left;
    height: 1px;
    background: #364688;
    position: relative;
    margin: 0 15px;
    margin-bottom: 20px;
    margin-top: 32px; }
  .footer .spacer1 {
    width: 100%;
    float: left;
    height: 1px;
    background: #364688;
    position: relative;
    margin: 0 15px;
    margin-bottom: 53px;
    margin-top: 32px; }
  .footer .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0; }
    @media only screen and (max-width: 1200px) {
      .footer .navigation {
        display: none; } }
    .footer .navigation a {
      color: #FFFFFF;
      font-size: 14px; }
  .footer .social {
    color: #FFFFFF;
    text-align: right; }
    .footer .social svg {
      fill: #FFFFFF;
      margin: 0 10px; }
  .footer .copyright {
    color: #FFFFFF;
    font-size: 16px;
    width: 100%;
    margin: 0 15px;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .footer .copyright a {
      color: #FFFFFF; }
    .footer .copyright > p {
      margin-bottom: 5px; }
      .footer .copyright > p > span {
        font-weight: 600; }

.select-field {
  border: 1px solid #D2D2D2;
  background-color: #FFFFFF;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 14px; }
  .select-field a {
    color: #13287E; }
  .select-field .dropdown-menu {
    width: 100%;
    max-height: 300px;
    overflow: auto; }
  .select-field .dropdown-toggle::after {
    display: none; }
  .select-field .dropdown-toggle::before {
    content: " ";
    display: block;
    border-top: 2px solid #13287E;
    border-right: 2px solid #13287E;
    width: 10px;
    height: 10px;
    transform: rotate(135deg);
    float: right;
    margin-top: 6px; }

.input-wrap {
  width: 100%; }
  .input-wrap input[type=text], .input-wrap input[type=email], .input-wrap input[type=password] {
    height: 50px;
    width: 100%;
    max-width: 500px;
    border-radius: 6px;
    border: 1px solid #D2D2D2;
    padding: 0 16px; }
  .input-wrap textarea {
    height: 150px;
    width: 100%;
    max-width: 500px;
    border-radius: 6px;
    border: 1px solid #D2D2D2;
    padding: 0 16px; }

.input-wrap {
  margin-bottom: 20px; }
  .input-wrap label {
    width: 100%;
    color: #13287E;
    font-size: 18px; }
  .input-wrap .required {
    border: 1px solid #DB3838 !important; }
  .input-wrap .select-field {
    width: 100%;
    max-width: 500px;
    height: 50px; }
    .input-wrap .select-field .dropdown-toggle {
      height: 50px !important; }
  .input-wrap .checkbox {
    border: 2px solid #D1D1D1;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 8px;
    border-radius: 4px;
    float: left; }
  .input-wrap .checked {
    display: flex;
    align-items: center;
    justify-content: center; }
    .input-wrap .checked::after {
      content: " ";
      display: block;
      border-right: 3px solid #36C4F4;
      border-bottom: 3px solid #36C4F4;
      width: 6px;
      height: 12px;
      transform: rotate(45deg); }
  .input-wrap .radio-button {
    border: 2px solid #D1D1D1;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 8px;
    border-radius: 50%;
    float: left; }
  .input-wrap .radio-button-checked {
    display: flex;
    align-items: center;
    justify-content: center; }
    .input-wrap .radio-button-checked::after {
      content: " ";
      display: block;
      width: 10px;
      height: 10px;
      background-color: #36C4F4;
      border-radius: 50%; }

.radio-button-wrap {
  display: flex;
  align-items: center;
  cursor: pointer; }

.sort-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px; }
  @media only screen and (max-width: 768px) {
    .sort-form {
      justify-content: space-between;
      padding-bottom: 15px; } }
  .sort-form > div {
    display: flex;
    align-items: center; }
    .sort-form > div > span {
      font-size: 14px;
      margin-right: 12px; }
  .sort-form .select-field {
    height: 40px;
    width: 200px;
    margin-right: 28px; }
    @media only screen and (max-width: 768px) {
      .sort-form .select-field {
        margin-right: 0; } }
  .sort-form .active {
    background-color: #36C4F4;
    border-radius: 10px; }
  @media only screen and (max-width: 768px) {
    .sort-form .btn {
      display: none; } }

.newsletter-form {
  font-size: 18px; }
  .newsletter-form .button {
    margin-right: 16px; }

.article {
  text-decoration: none !important; }
  .article article {
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    min-height: 345px;
    border-radius: 20px; }
    @media only screen and (max-width: 768px) {
      .article article {
        min-height: 198px; } }
    .article article:hover {
      -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.2s ease-in-out; }
    .article article img {
      padding: 0px;
      width: 100%;
      height: 280px;
      object-fit: cover;
      border-radius: 20px 20px 0 0; }
      @media only screen and (max-width: 768px) {
        .article article img {
          height: 145px; } }
    .article article h6 {
      margin: 20px 20px 11px 20px;
      font-size: 19px;
      font-weight: 700;
      text-align: center;
      color: #13287E;
      text-decoration: none !important;
      height: 45px;
      overflow: hidden; }
      @media only screen and (max-width: 768px) {
        .article article h6 {
          font-size: 16px;
          margin: 0 10px 10px 10px;
          height: 72px; } }
    .article article .cat {
      font-size: 16px;
      font-weight: 400;
      margin: 14px 20px 2px 20px;
      height: unset; }
    .article article p {
      font-size: 14px;
      color: #13287E;
      padding: 0 20px 0 20px;
      text-align: center; }
      @media only screen and (max-width: 1200px) {
        .article article p {
          display: none; } }
    .article article .price {
      padding: 0 20px 14px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none !important;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;
      color: #36C4F4;
      text-align: center;
      justify-content: space-around; }
      @media only screen and (max-width: 768px) {
        .article article .price {
          padding: 0 10px 10px 10px;
          font-size: 16px; } }
      .article article .price .package {
        font-size: 20px; }
      .article article .price .old-price {
        font-size: 20px;
        font-weight: 700;
        text-decoration: line-through;
        color: #D2D2D2; }

@media only screen and (max-width: 768px) {
  .article-container:nth-child(2n) {
    padding-left: 7.5px; }
  .article-container:nth-child(2n+1) {
    padding-right: 7.5px; } }

@media only screen and (max-width: 768px) {
  .article-container1:nth-child(2n) {
    padding-right: 7.5px; }
  .article-container1:nth-child(2n+1) {
    padding-left: 7.5px; } }

.list-article {
  text-decoration: none !important; }
  .list-article article {
    background-color: #FFFFFF;
    margin-bottom: 30px;
    width: 100%;
    height: 180px;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex; }
    .list-article article:hover {
      -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.2s ease-in-out; }
    .list-article article img {
      width: 200px;
      max-width: 200px;
      min-width: 200px;
      height: 178px;
      object-fit: cover;
      float: left;
      border-radius: 20px 0 0 20px; }
    .list-article article > div {
      float: left;
      padding: 16px;
      width: 100%;
      position: relative; }
      .list-article article > div h6 {
        margin: 14px 20px 11px 20px;
        font-size: 22px;
        font-weight: 600;
        color: #13287E;
        text-decoration: none !important;
        height: 44px;
        overflow: hidden; }
      .list-article article > div p {
        position: absolute;
        bottom: 16px;
        width: 100%;
        padding: 0 40px 0px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none !important;
        margin-bottom: 0;
        line-height: 18px; }
        .list-article article > div p .price {
          color: #36C4F4;
          font-size: 18px;
          font-weight: 600; }
        .list-article article > div p .in-stock {
          margin-left: auto;
          font-size: 16px;
          color: #13287E;
          display: flex;
          align-items: center; }
          .list-article article > div p .in-stock::after {
            content: " ";
            display: block;
            background-color: #00D20E;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            float: right;
            margin-left: 10px; }
      .list-article article > div .short {
        color: #13287E;
        font-weight: 300;
        padding: 0;
        height: 48px;
        bottom: unset;
        top: unset;
        position: unset;
        padding: 0 40px 0px 20px;
        left: unset;
        margin-top: -10px;
        max-width: 90%; }
        .list-article article > div .short p {
          all: unset; }
      .list-article article > div .old-price {
        font-size: 16px;
        font-weight: 400;
        text-decoration: line-through;
        color: #D2D2D2;
        margin-left: 20px; }

.alert {
  background-color: #DDF9EB;
  border: 1px solid #36C4F4;
  font-size: 18px;
  min-height: 50px;
  margin-bottom: 34px;
  padding: 10px 18px;
  border-radius: 6px; }

.car-animation {
  position: relative;
  animation-name: car-animation;
  animation-duration: 2s; }

@keyframes car-animation {
  from {
    top: -50px;
    right: -80px;
    transform: scale(0.7); }
  to {
    top: 0px;
    right: 0;
    transform: scale(1); } }

.top-bottom-animation {
  position: relative;
  animation-name: top-bottom;
  animation-duration: 1s; }

@keyframes top-bottom {
  from {
    top: -200px; }
  to {
    top: 0px; } }

.dropdown-animation {
  animation-name: dropdown-animation;
  animation-duration: 0.2s; }

@keyframes dropdown-animation {
  from {
    transform: translateY(60px);
    opacity: 0; }
  to {
    transform: translateY(48px);
    opacity: 1; } }

@keyframes bubble-animation {
  0% {
    border: 10px solid rgba(255, 255, 255, 0); }
  50% {
    border: 20px solid rgba(255, 255, 255, 0.5); }
  100% {
    border: 10px solid rgba(255, 255, 255, 0); } }

.bubble-animation {
  animation-iteration-count: infinite;
  animation-name: bubble-animation;
  animation-duration: 2s; }

@keyframes active-menu-animation {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(70%, 10vh, 0); } }

.active-menu-animation {
  transform: translate3d(70%, 10vh, 0);
  position: fixed;
  width: 100% !important;
  position: fixed !important;
  height: 80vh !important;
  overflow-x: auto;
  overflow-y: scroll;
  animation-name: active-menu-animation;
  animation-duration: 1s;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16); }

.home-wrap {
  /*.categories {
        background-color: $white;
        float: left;
        width: 100%;
        .submenu {
            display: none;
        }
        > ul{
            min-height: 490px;
        }
        ul {
            width: 100%;
            list-style: none;
            padding: 22px 16px;
            margin: 0;
            border: 1px solid $field-border-color;
            border-top: none;
            float: left;
            >li {
                width: 100%;
                float: left;
                //padding: 0 16px;
                //border-bottom: 1px solid $gray;

                &::before {
                    content: " ";
                    display: inline-block;
                    background-color: $black;
                    border-radius: 50%;
                    width: 6px;
                    height: 6px;
                    margin-top: 16px;
                    margin-right: 5px;
                    float: left;
                    margin-right: 12px;
                }



                &:last-child{
                    border-bottom: 0;
                }

                > a {
                    float: left;
                    color: $black;
                    padding: 8px 0;
                    font-size: 16px;
    
                }
                &:hover {
                    > a {
                        font-weight: 600;
                        color: $primary;
                    }
                    .submenu {
                        border-radius: 0 10px 10px 0;
                        //height: 431px;
                        height: 100%;
                        position: absolute;
                        left: 250px;
                        background: white;
                        z-index: 16;
                        top: 0;
                        padding-right: 40px;
                        border-top: 3px solid #000;
                        border-bottom: 3px solid #000;
                        border-right: 3px solid #000;
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
                        min-width: 875px;
                        h5 {
                            font-size: 22px;
                            font-weight: $semiBold;
                            line-height: 26px;
                            color: $black;
                            margin-left: 13px;
                            margin-top: 15px;
                        }
                        li {
                            margin-left: 13px;
                            float: none;
                            display: flex;
                            justify-content: space-between;
                            width: 236px;
                            &:after {
                                margin-right: 0px;
                            }
                            &:last-child {
                                border-bottom: 1px solid #CCCCCC;
                                margin-bottom: 14px;
                            }
                        }
                        ul {
                            border: none;
                        }
                    }
                }
            }
        }
        > ul{
            //padding: 7px 0;
        }
    }*/ }
  .home-wrap .main {
    padding-bottom: 20px; }
    .home-wrap .main h1 {
      display: none; }
  .home-wrap .category-banner img {
    height: 390px;
    width: 100%;
    object-fit: cover;
    object-position: center; }
    @media only screen and (max-width: 1200px) {
      .home-wrap .category-banner img {
        height: 250px; } }
  .home-wrap .home-slider-wrap {
    position: relative; }
    .home-wrap .home-slider-wrap .num-indicator {
      position: absolute;
      left: 50%;
      margin-left: 460px;
      bottom: 10px;
      font-size: 82px;
      color: #FFFFFF;
      display: flex;
      font-weight: 700;
      font-style: italic; }
      .home-wrap .home-slider-wrap .num-indicator > span {
        font-size: 36px;
        padding-top: 30px;
        margin-left: 10px;
        font-weight: 400; }
  .home-wrap .section-info {
    padding-top: 0;
    margin-top: -70px;
    position: relative; }
    @media only screen and (max-width: 1200px) {
      .home-wrap .section-info {
        margin-top: 0; } }
    .home-wrap .section-info .container {
      background-color: #36C4F4 !important;
      padding-top: 13px;
      padding-bottom: 22px; }
      @media only screen and (max-width: 1200px) {
        .home-wrap .section-info .container {
          padding-bottom: 13px; } }
    .home-wrap .section-info h6 {
      color: #FFFFFF;
      text-align: center;
      font-size: 54px;
      font-weight: 600; }
      @media only screen and (max-width: 1200px) {
        .home-wrap .section-info h6 {
          font-size: 24px; } }
    .home-wrap .section-info p {
      color: #FFFFFF;
      text-align: center;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 0; }
      @media only screen and (max-width: 1200px) {
        .home-wrap .section-info p {
          font-size: 10px; } }
  .home-wrap .home-slider {
    height: 630px;
    overflow: hidden;
    background-color: #FFFFFF;
    /*@media only screen and (min-width: 768px) and (max-width: 900px) {
            height: 215px;

        }*/ }
    @media only screen and (max-width: 1200px) {
      .home-wrap .home-slider {
        height: 215px; } }
    .home-wrap .home-slider .carousel-control-prev {
      position: absolute;
      background-color: transparent;
      color: transparent;
      opacity: 1;
      left: 50%;
      margin-left: 426px;
      bottom: 60px;
      top: unset;
      height: 60px;
      width: 40px; }
      .home-wrap .home-slider .carousel-control-prev > span {
        display: none; }
      .home-wrap .home-slider .carousel-control-prev::after {
        content: ' ';
        border-left: 2px solid #FFFFFF;
        border-top: 2px solid #FFFFFF;
        transform: rotate(-45deg);
        width: 12px;
        height: 12px;
        position: absolute;
        top: 24px;
        left: 14px; }
    .home-wrap .home-slider .carousel-control-next {
      position: absolute;
      background-color: transparent;
      opacity: 1;
      left: 50%;
      margin-left: 612px;
      bottom: 60px;
      height: 60px;
      width: 40px;
      top: unset; }
      .home-wrap .home-slider .carousel-control-next > span {
        display: none; }
      .home-wrap .home-slider .carousel-control-next::after {
        content: ' ';
        border-left: 2px solid #FFFFFF;
        border-top: 2px solid #FFFFFF;
        transform: rotate(135deg);
        width: 12px;
        height: 12px;
        position: absolute;
        top: 24px;
        left: 14px; }
    .home-wrap .home-slider .carousel-indicators {
      left: 0;
      margin-left: 50px;
      width: 1224px;
      margin: 0 auto;
      justify-content: flex-start;
      bottom: 120px; }
      @media only screen and (max-width: 1200px) {
        .home-wrap .home-slider .carousel-indicators {
          margin-left: 20px;
          margin-bottom: 0px;
          display: none; } }
      .home-wrap .home-slider .carousel-indicators li {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #36C4F4; }
        @media only screen and (max-width: 1200px) {
          .home-wrap .home-slider .carousel-indicators li {
            width: 8px;
            height: 8px; } }
      .home-wrap .home-slider .carousel-indicators .active {
        border: none;
        background-color: #13287E; }
    .home-wrap .home-slider .carousel-item {
      background-color: #D9EEF9; }
      .home-wrap .home-slider .carousel-item img {
        width: 60%;
        height: 630px;
        background-color: #FFFFFF;
        position: absolute;
        top: 0;
        right: 0;
        object-fit: cover; }
        @media only screen and (max-width: 1200px) {
          .home-wrap .home-slider .carousel-item img {
            height: 215px;
            width: 50%;
            object-fit: cover;
            object-position: right; } }
        @media only screen and (min-width: 768px) and (max-width: 900px) {
          .home-wrap .home-slider .carousel-item img {
            height: 300px; } }
      .home-wrap .home-slider .carousel-item .content {
        width: 100%;
        height: 100%;
        height: 630px;
        display: flex;
        flex-direction: column; }
        @media only screen and (max-width: 1200px) {
          .home-wrap .home-slider .carousel-item .content {
            height: 215px;
            width: 50%; } }
        .home-wrap .home-slider .carousel-item .content .container {
          height: 100%; }
        .home-wrap .home-slider .carousel-item .content .row {
          height: 100%; }
        .home-wrap .home-slider .carousel-item .content .content-col {
          padding-top: 51px; }
          @media only screen and (max-width: 1200px) {
            .home-wrap .home-slider .carousel-item .content .content-col {
              margin-top: 0;
              padding-top: 12px;
              height: 215px;
              display: flex;
              flex-direction: column;
              justify-content: center; } }
        .home-wrap .home-slider .carousel-item .content h3 {
          color: #13287E;
          font-size: 39px;
          font-weight: 800;
          margin: 0 auto;
          margin-bottom: 11px; }
          .home-wrap .home-slider .carousel-item .content h3::before {
            content: " ";
            width: 45px;
            display: block;
            height: 36px;
            background-image: url(./assets/svg/stars.svg); }
            @media only screen and (max-width: 1200px) {
              .home-wrap .home-slider .carousel-item .content h3::before {
                display: none; } }
          @media only screen and (max-width: 1200px) {
            .home-wrap .home-slider .carousel-item .content h3 {
              font-size: 14px;
              margin-bottom: 16px; } }
        .home-wrap .home-slider .carousel-item .content p {
          color: #13287E;
          font-size: 15px;
          margin: 0 auto;
          margin-bottom: 45px; }
          @media only screen and (max-width: 1119px) {
            .home-wrap .home-slider .carousel-item .content p {
              display: none; } }
        .home-wrap .home-slider .carousel-item .content .app-button img {
          all: unset;
          height: 50px; }
          @media only screen and (max-width: 1200px) {
            .home-wrap .home-slider .carousel-item .content .app-button img {
              height: 30px; } }
        .home-wrap .home-slider .carousel-item .content .app-buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 50px; }
          @media only screen and (max-width: 1200px) {
            .home-wrap .home-slider .carousel-item .content .app-buttons {
              flex-direction: column;
              align-items: flex-start; } }
        .home-wrap .home-slider .carousel-item .content .button {
          color: #13287E;
          background-color: #FFFFFF;
          border-radius: 0;
          padding: 15px 28px;
          text-decoration: none;
          font-size: 14px;
          font-weight: 600;
          border-radius: 119px;
          margin-right: 30px;
          -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
          -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08); }
          @media only screen and (max-width: 1200px) {
            .home-wrap .home-slider .carousel-item .content .button {
              margin-right: 0;
              max-width: 130px; } }
          .home-wrap .home-slider .carousel-item .content .button:nth-child(3) {
            background-color: #FFF323; }
          @media only screen and (max-width: 1200px) {
            .home-wrap .home-slider .carousel-item .content .button:nth-child(4) {
              display: none; } }
          .home-wrap .home-slider .carousel-item .content .button .isvg {
            margin-left: 20px; }
            .home-wrap .home-slider .carousel-item .content .button .isvg svg {
              width: 21px;
              height: 16px; }
          @media only screen and (max-width: 1200px) {
            .home-wrap .home-slider .carousel-item .content .button {
              font-size: 12px;
              padding: 8px; } }
  .home-wrap .banners {
    margin-top: 28px; }
    .home-wrap .banners .banner {
      margin-bottom: 20px;
      height: 140px; }
      .home-wrap .banners .banner img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .home-wrap .section-articles {
    padding-top: 40px;
    padding-bottom: 60px; }
    @media only screen and (max-width: 1200px) {
      .home-wrap .section-articles {
        padding-top: 40px;
        padding-bottom: 10px; } }
    .home-wrap .section-articles .title {
      text-align: center;
      margin-bottom: 58px; }
      @media only screen and (max-width: 1200px) {
        .home-wrap .section-articles .title {
          margin-bottom: 20px; } }
      .home-wrap .section-articles .title h2 {
        color: #13287E;
        font-size: 40px;
        font-weight: 900;
        margin-bottom: 0; }
        @media only screen and (max-width: 1200px) {
          .home-wrap .section-articles .title h2 {
            font-size: 26px; } }
      .home-wrap .section-articles .title p {
        font-size: 15px;
        color: #13287E;
        margin-bottom: 0; }
    .home-wrap .section-articles .btn-wrap {
      display: flex;
      justify-content: center; }
    .home-wrap .section-articles .button {
      color: #13287E;
      border-radius: 0;
      padding: 12px 48px;
      text-decoration: none;
      font-size: 16px;
      font-weight: 600;
      border-radius: 27px;
      background-color: #FFF323;
      border: 0;
      margin-top: 20px;
      -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08); }
      @media only screen and (max-width: 1200px) {
        .home-wrap .section-articles .button {
          font-size: 12px;
          padding: 8px; } }
    .home-wrap .section-articles a {
      text-decoration: none; }
  .home-wrap .section-reviews .reviews-slider {
    background-color: #36C4F4;
    padding-top: 30px;
    padding-bottom: 30px; }
    .home-wrap .section-reviews .reviews-slider .carousel-indicators li {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #FFFFFF;
      background-color: transparent; }
    .home-wrap .section-reviews .reviews-slider .carousel-indicators .active {
      border: none;
      background-color: #FFFFFF; }
    .home-wrap .section-reviews .reviews-slider .review img {
      width: 160px;
      height: 160px;
      border-radius: 50%; }
    .home-wrap .section-reviews .reviews-slider .review p {
      color: #FFFFFF;
      margin-top: 11px;
      font-size: 18px; }
    .home-wrap .section-reviews .reviews-slider .review .user {
      margin-top: 32px;
      font-size: 20px;
      font-weight: 600; }
  .home-wrap .section-news {
    padding-top: 60px;
    padding-bottom: 100px;
    background-color: #D9EEF9; }
    .home-wrap .section-news a {
      text-decoration: none; }
    .home-wrap .section-news .title {
      text-align: center;
      margin-bottom: 58px; }
      .home-wrap .section-news .title h2 {
        color: #13287E;
        font-size: 40px;
        font-weight: 900;
        margin-bottom: 0; }
        @media only screen and (max-width: 1200px) {
          .home-wrap .section-news .title h2 {
            font-size: 26px; } }
      .home-wrap .section-news .title p {
        font-size: 15px;
        color: #13287E;
        margin-bottom: 0; }
    .home-wrap .section-news article {
      text-align: center;
      -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      background-color: #FFFFFF;
      padding-bottom: 24px;
      border-radius: 20px;
      min-height: 448px; }
      @media only screen and (max-width: 1200px) {
        .home-wrap .section-news article {
          margin-bottom: 15px; } }
      .home-wrap .section-news article img {
        width: 100%;
        height: 200px;
        margin-bottom: 18px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px; }
      .home-wrap .section-news article h6 {
        font-size: 20px;
        font-weight: 700;
        color: #13287E;
        margin-bottom: 9px; }
      .home-wrap .section-news article P {
        font-size: 16px;
        color: #13287E;
        padding: 0 30px; }
      .home-wrap .section-news article .date {
        color: #36C4F4;
        font-size: 14px; }

.slider-container {
  position: relative;
  padding-right: 0;
  padding-left: 0; }
  .slider-container .slick-arrow {
    background-color: #FFFFFF;
    outline: none;
    border: 0;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    color: transparent;
    width: 64px;
    height: 64px;
    position: absolute; }
  .slider-container .slick-next {
    top: 50%;
    right: -100px;
    margin-top: -32px; }
    .slider-container .slick-next::after {
      content: ' ';
      display: block;
      border-top: 2px solid #13287E;
      border-left: 2px solid #13287E;
      width: 18px;
      height: 18px;
      transform: rotate(135deg);
      position: absolute;
      top: 24px;
      left: 20px; }
  .slider-container .slick-prev {
    top: 50%;
    left: -100px;
    margin-top: -32px; }
    .slider-container .slick-prev::after {
      content: ' ';
      display: block;
      border-top: 2px solid #13287E;
      border-left: 2px solid #13287E;
      width: 18px;
      height: 18px;
      transform: rotate(-45deg);
      position: absolute;
      top: 24px;
      left: 24px; }
  .slider-container .slick-slider {
    overflow: hidden; }
  .slider-container .slick-list .slick-slide {
    float: left; }
    .slider-container .slick-list .slick-slide > div {
      padding: 0 15px; }

.section-categories {
  padding: 50px 0;
  background-color: #FFFFFF; }
  @media only screen and (max-width: 1200px) {
    .section-categories {
      padding: 20px 0;
      margin-bottom: 20px; } }
  .section-categories .category-box {
    text-align: center; }
    .section-categories .category-box img {
      width: 100%;
      height: 260px;
      object-position: center;
      object-fit: cover; }
      @media only screen and (max-width: 1200px) {
        .section-categories .category-box img {
          border-radius: 50%;
          width: 70px;
          height: 70px; } }
    .section-categories .category-box h3 {
      font-size: 22px;
      font-weight: 600;
      margin-top: 20px;
      color: #36C4F4; }
      @media only screen and (max-width: 1200px) {
        .section-categories .category-box h3 {
          font-size: 18px; } }
    .section-categories .category-box a {
      text-decoration: none !important; }

.news-item a {
  text-decoration: none; }

.news-item article {
  text-align: center;
  /*-webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);*/
  background-color: #FFFFFF;
  padding-bottom: 24px;
  border: 1px dashed #D2D2D2;
  border-radius: 20px; }
  @media only screen and (max-width: 1200px) {
    .news-item article {
      margin-bottom: 15px; } }
  .news-item article img {
    width: 100%;
    height: 200px;
    margin-bottom: 18px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px; }
  .news-item article h6 {
    font-size: 22px;
    font-weight: 600;
    color: #13287E;
    margin-bottom: 14px; }
  .news-item article P {
    font-size: 18px;
    color: #13287E;
    margin-bottom: 20px;
    padding: 0 30px; }
  .news-item article a {
    color: #36C4F4;
    text-decoration: none;
    font-size: 20px;
    font-weight: 300; }

.mobile-currency {
  display: none;
  position: fixed;
  right: 0;
  bottom: 50px;
  background-color: #36C4F4;
  border-radius: 6px 0 0 6px;
  padding: 12px 12px;
  color: #FFFFFF; }
  @media only screen and (max-width: 1200px) {
    .mobile-currency {
      display: block; } }
  .mobile-currency a {
    color: #FFFFFF;
    font-weight: 600;
    margin: 0 5px; }

.section-badges {
  padding-top: 57px;
  padding-bottom: 74px; }
  @media only screen and (max-width: 1200px) {
    .section-badges {
      padding-top: 20px;
      padding-bottom: 20px; } }
  .section-badges a {
    text-decoration: none; }
  @media only screen and (max-width: 1200px) {
    .section-badges .badge {
      margin-bottom: 30px; } }
  .section-badges .badge .icon {
    background-color: #D9EEF9;
    border-radius: 50%;
    width: 124px;
    height: 124px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 26px; }
    @media only screen and (max-width: 1200px) {
      .section-badges .badge .icon {
        width: 80px;
        height: 80px; }
        .section-badges .badge .icon svg {
          height: 40px; } }
  .section-badges .badge h6 {
    color: #13287E;
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 19px; }
    @media only screen and (max-width: 1200px) {
      .section-badges .badge h6 {
        font-size: 22px; } }
  .section-badges .badge p {
    font-size: 16px;
    text-align: center;
    color: #13287E;
    font-weight: 400;
    white-space: pre-wrap;
    margin-bottom: 20px;
    min-height: 32px;
    line-height: 22px; }
    @media only screen and (max-width: 1200px) {
      .section-badges .badge p {
        font-size: 12px;
        min-height: 48px; } }
  .section-badges .badge button {
    border: 2px solid #13287E;
    border-radius: 27px;
    background-color: transparent;
    padding: 12px 54px;
    font-size: 16px;
    font-weight: 600; }
    @media only screen and (max-width: 1200px) {
      .section-badges .badge button {
        padding: 8px 25px; } }

.section-services {
  background-color: #D9EEF9;
  padding-top: 50px;
  padding-bottom: 74px; }
  @media only screen and (max-width: 1200px) {
    .section-services {
      padding-bottom: 20px; } }
  .section-services .title {
    text-align: center;
    margin-bottom: 58px; }
    .section-services .title h2 {
      color: #13287E;
      font-size: 40px;
      font-weight: 900;
      margin-bottom: 0; }
      @media only screen and (max-width: 1200px) {
        .section-services .title h2 {
          font-size: 26px; } }
    .section-services .title p {
      font-size: 15px;
      color: #13287E;
      margin-bottom: 0; }
  .section-services .service {
    text-align: center; }
    @media only screen and (max-width: 1200px) {
      .section-services .service {
        margin-bottom: 30px; } }
    .section-services .service img {
      width: 100%;
      border-radius: 20px;
      margin-bottom: 40px; }
    .section-services .service h3 {
      font-size: 32px;
      font-weight: 700;
      color: #13287E; }
    .section-services .service p {
      font-size: 15px; }
    .section-services .service .button {
      color: #13287E;
      border-radius: 0;
      padding: 15px 28px;
      text-decoration: none;
      font-size: 14px;
      font-weight: 600;
      border-radius: 119px;
      -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
      background-color: #FFF323; }
      @media only screen and (max-width: 1200px) {
        .section-services .service .button {
          font-size: 12px;
          padding: 8px; } }

.section-brands {
  padding-top: 50px;
  padding-bottom: 74px; }
  .section-brands .title {
    text-align: center;
    margin-bottom: 15px; }
    .section-brands .title h2 {
      color: #13287E;
      font-size: 40px;
      font-weight: 900;
      margin-bottom: 0; }
      @media only screen and (max-width: 1200px) {
        .section-brands .title h2 {
          font-size: 26px; } }
    .section-brands .title p {
      font-size: 15px;
      color: #13287E;
      margin-bottom: 0; }
  .section-brands .brand {
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    min-height: 140px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 30px 0; }
    @media only screen and (max-width: 1200px) {
      .section-brands .brand img {
        width: 100%; } }
  .section-brands .btn-wrap {
    display: flex;
    justify-content: center; }
  .section-brands .button {
    color: #13287E;
    border-radius: 0;
    padding: 12px 48px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    border-radius: 27px;
    background-color: transparent;
    border: 2px solid #13287E; }
    @media only screen and (max-width: 1200px) {
      .section-brands .button {
        font-size: 12px;
        padding: 8px; } }

.section-users {
  padding-top: 50px;
  padding-bottom: 74px;
  margin: 0;
  background-color: #D9EEF9; }
  .section-users .title {
    text-align: center;
    margin-bottom: 40px; }
    .section-users .title h2 {
      color: #13287E;
      font-size: 40px;
      font-weight: 900;
      margin-bottom: 0; }
      @media only screen and (max-width: 1200px) {
        .section-users .title h2 {
          font-size: 26px; } }
    .section-users .title p {
      font-size: 15px;
      color: #13287E;
      margin-bottom: 0; }
  .section-users .user {
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    min-height: 140px;
    text-align: center;
    margin-bottom: 40px;
    padding: 40px 30px;
    color: #13287E; }
    .section-users .user img {
      width: 160px;
      height: 160px;
      border-radius: 80px;
      margin-bottom: 30px; }
    .section-users .user h6 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 4px; }
    .section-users .user p {
      font-size: 14px; }
    .section-users .user a {
      font-size: 14px; }
  .section-users .btn-wrap {
    display: flex;
    justify-content: center; }
  .section-users .button {
    color: #13287E;
    border-radius: 0;
    padding: 12px 48px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    border-radius: 27px;
    background-color: transparent;
    border: 2px solid #13287E; }
    @media only screen and (max-width: 1200px) {
      .section-users .button {
        font-size: 12px;
        padding: 8px; } }

.section-gallery {
  padding-top: 50px;
  padding-bottom: 74px;
  margin: 0; }
  .section-gallery .title {
    text-align: center;
    margin-bottom: 40px; }
    .section-gallery .title h2 {
      color: #13287E;
      font-size: 40px;
      font-weight: 900;
      margin-bottom: 0; }
      @media only screen and (max-width: 1200px) {
        .section-gallery .title h2 {
          font-size: 26px; } }
    .section-gallery .title p {
      font-size: 15px;
      color: #13287E;
      margin-bottom: 0; }
  .section-gallery img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding-bottom: 30px; }
  .section-gallery .no-padding {
    padding-left: 0;
    padding-right: 0; }

.section-list-news {
  padding-top: 60px; }
  .section-list-news a {
    text-decoration: none; }
  .section-list-news .title {
    text-align: center;
    margin-bottom: 58px; }
    .section-list-news .title h2 {
      color: #13287E;
      font-size: 40px;
      font-weight: 900;
      margin-bottom: 0; }
      @media only screen and (max-width: 1200px) {
        .section-list-news .title h2 {
          font-size: 26px; } }
    .section-list-news .title p {
      font-size: 15px;
      color: #13287E;
      margin-bottom: 0; }
  .section-list-news article {
    all: unset;
    background-color: #FFFFFF; }
    .section-list-news article .container {
      -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin-bottom: 40px !important; }
    .section-list-news article .image {
      padding-right: 0; }
      @media only screen and (max-width: 1200px) {
        .section-list-news article .image {
          padding-left: 0; } }
    .section-list-news article img {
      width: 100%;
      height: 100%;
      border-radius: 0 10px 10px 0 !important; }
      @media only screen and (max-width: 1200px) {
        .section-list-news article img {
          border-radius: 0 0 10px 10px  !important; } }
    .section-list-news article .content {
      padding: 0 30px; }
    .section-list-news article h6 {
      font-size: 26px;
      font-weight: 700;
      color: #13287E;
      margin-bottom: 9px;
      margin-top: 30px; }
    .section-list-news article P {
      font-size: 17px;
      color: #13287E;
      margin-bottom: 20px;
      padding: 0; }
    .section-list-news article .date {
      color: #36C4F4;
      font-size: 15px; }
    .section-list-news article a {
      color: #36C4F4;
      font-size: 15px;
      text-decoration: none;
      padding: 0;
      font-weight: 400;
      text-decoration: underline;
      padding-bottom: 5px; }
  .section-list-news .left-image img {
    border-radius: 10px 0 0 10px !important; }
    @media only screen and (max-width: 1200px) {
      .section-list-news .left-image img {
        border-radius: 10px 10px 0 0 !important; } }
  .section-list-news .left-image .image {
    padding-left: 0; }

.locations-map {
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  height: 770px; }
  @media only screen and (max-width: 1200px) {
    .locations-map {
      height: 350px; } }

.category-wrap {
  padding-top: 145px; }
  @media only screen and (max-width: 1200px) {
    .category-wrap {
      padding-top: 70px; } }
  .category-wrap .category-image {
    width: 100%;
    margin-bottom: 15px; }
  .category-wrap h1 {
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 7px;
    color: #13287E; }
  .category-wrap .pagination {
    float: left; }
    .category-wrap .pagination > li {
      width: 40px;
      height: 40px;
      border-radius: 10px;
      background-color: #E5E5E5;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #36C4F4;
      font-weight: 600;
      font-size: 16px;
      margin-right: 10px;
      cursor: pointer; }
      .category-wrap .pagination > li a {
        color: #36C4F4;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
      .category-wrap .pagination > li:hover {
        color: #FFFFFF;
        text-decoration: none;
        background-color: #36C4F4; }
        .category-wrap .pagination > li:hover a {
          text-decoration: none;
          color: #FFFFFF; }
    .category-wrap .pagination .break-me:hover {
      background-color: #E5E5E5;
      cursor: default; }
      .category-wrap .pagination .break-me:hover a {
        color: #36C4F4; }
    .category-wrap .pagination .active {
      color: #FFFFFF;
      background-color: #36C4F4; }
      .category-wrap .pagination .active a {
        color: #FFFFFF; }
    .category-wrap .pagination .previous {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center; }
      .category-wrap .pagination .previous::after {
        content: ' ';
        border-top: 0;
        border-right: 0;
        border-bottom: 2px solid #36C4F4;
        border-left: 2px solid #36C4F4;
        padding: 4px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        top: 16px;
        left: 16px; }
      .category-wrap .pagination .previous:hover::after {
        border-bottom: 2px solid #FFFFFF;
        border-left: 2px solid #FFFFFF; }
    .category-wrap .pagination .next {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center; }
      .category-wrap .pagination .next::after {
        content: ' ';
        border-top: 0;
        border-right: 0;
        border-bottom: 2px solid #36C4F4;
        border-left: 2px solid #36C4F4;
        padding: 4px;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        top: 16px;
        left: 16px; }
      .category-wrap .pagination .next:hover::after {
        border-bottom: 2px solid #FFFFFF;
        border-left: 2px solid #FFFFFF; }
  @media only screen and (max-width: 768px) {
    .category-wrap .articles {
      padding: 0; } }
  .category-wrap .content-wrap {
    margin-top: 29px; }

.detail-wrap {
  padding-top: 145px; }
  @media only screen and (max-width: 1200px) {
    .detail-wrap {
      padding-top: 70px; } }
  .detail-wrap h1 {
    font-size: 32px;
    font-weight: 600; }
  .detail-wrap .content-wrap {
    margin-top: 29px; }
  .detail-wrap .section-articles {
    padding-top: 40px; }
    .detail-wrap .section-articles .title {
      text-align: center;
      margin-bottom: 58px; }
      .detail-wrap .section-articles .title h2 {
        color: #13287E;
        font-size: 40px;
        font-weight: 900;
        margin-bottom: 0; }
        @media only screen and (max-width: 1200px) {
          .detail-wrap .section-articles .title h2 {
            font-size: 26px; } }
      .detail-wrap .section-articles .title p {
        font-size: 15px;
        color: #13287E;
        margin-bottom: 0; }
    .detail-wrap .section-articles a {
      text-decoration: none; }
  .detail-wrap .article-box {
    border-radius: 10px;
    -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    margin-bottom: 30px;
    padding: 35px;
    padding-bottom: 26px; }
    @media only screen and (max-width: 768px) {
      .detail-wrap .article-box {
        padding: 15px;
        padding-bottom: 26px; } }
    .detail-wrap .article-box ul {
      list-style: none; }
    .detail-wrap .article-box table {
      width: 100%;
      overflow-x: auto;
      max-width: 100%;
      border: 1px solid #36C4F4; }
      .detail-wrap .article-box table tr th {
        background-color: #36C4F4;
        padding: 16px;
        color: #FFFFFF; }
      .detail-wrap .article-box table tr td {
        border: 1px solid #36C4F4;
        padding: 16px; }
    .detail-wrap .article-box .variations-table {
      max-width: 100%;
      overflow: auto; }
    .detail-wrap .article-box .variations table tr th {
      font-size: 12px;
      padding: 6px; }
    .detail-wrap .article-box .variations table tr td {
      font-size: 12px;
      padding: 6px; }
    .detail-wrap .article-box h2 {
      font-size: 42px;
      font-weight: 600;
      display: flex;
      color: #222;
      justify-content: space-between; }
      @media only screen and (max-width: 768px) {
        .detail-wrap .article-box h2 {
          font-size: 24px; } }
    .detail-wrap .article-box h3 {
      font-size: 26px;
      font-weight: 400; }
      @media only screen and (max-width: 768px) {
        .detail-wrap .article-box h3 {
          font-size: 20px; } }
    .detail-wrap .article-box h4 {
      color: #C2CC50;
      font-weight: 700;
      font-size: 22px;
      margin-bottom: 4px; }
    .detail-wrap .article-box .stock {
      font-size: 18px;
      color: #13287E;
      font-weight: 300;
      display: flex;
      align-items: center;
      margin-bottom: 29px; }
      .detail-wrap .article-box .stock .out-of-stock {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #DE3636;
        float: left;
        margin-right: 14px; }
      .detail-wrap .article-box .stock .in-stock {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #00D20E;
        float: left;
        margin-right: 14px; }
    .detail-wrap .article-box .spacer {
      width: 100%;
      background-color: #CCCCCC;
      height: 1px;
      margin: 18px 0;
      float: left; }
    .detail-wrap .article-box .price {
      width: 100%;
      float: left;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      color: #36C4F4;
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 28px; }
      .detail-wrap .article-box .price span {
        color: #13287E;
        font-size: 36px;
        font-weight: 700;
        margin-top: auto; }
      .detail-wrap .article-box .price .old-price {
        color: #D2D2D2;
        font-size: 30px;
        font-weight: 300;
        text-decoration: line-through;
        margin-right: 27px; }
    .detail-wrap .article-box .buttons {
      float: left;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      align-items: center;
      display: none; }
      @media only screen and (min-width: 768px) {
        .detail-wrap .article-box .buttons {
          display: flex; } }
      .detail-wrap .article-box .buttons input {
        height: 35px;
        width: 60px;
        border-radius: 25px;
        border: 1px solid #D2D2D2;
        padding: 0 16px;
        margin-left: 15px; }
      @media only screen and (max-width: 768px) {
        .detail-wrap .article-box .buttons {
          margin-bottom: 0px; } }
      .detail-wrap .article-box .buttons .button {
        font-size: 16px;
        font-weight: 600;
        color: #FFFFFF;
        border-radius: 10px;
        background-color: #36C4F4;
        padding: 12px 18px 11px 18px; }
      .detail-wrap .article-box .buttons .button-black {
        background-color: #C2CC50; }
      .detail-wrap .article-box .buttons .wishlist-btn {
        background-color: #FFF323;
        border: 0;
        border-radius: 10px;
        outline: none;
        padding: 10px 18px; }
    .detail-wrap .article-box .mobile-buttons {
      float: left;
      width: 100%;
      flex-direction: column;
      display: flex; }
      .detail-wrap .article-box .mobile-buttons .button {
        font-size: 16px;
        font-weight: 600;
        color: #FFFFFF;
        border-radius: 0;
        background-color: #13287E;
        padding: 12px 18px 11px 18px;
        border-radius: 27px;
        width: 100%; }
      .detail-wrap .article-box .mobile-buttons .button-black {
        background-color: #36C4F4;
        margin-left: 20px;
        border-radius: 27px; }
      .detail-wrap .article-box .mobile-buttons .wishlist-btn {
        background-color: #FFF323;
        border: 0;
        outline: none;
        padding: 10px 10px;
        margin-left: 65px;
        border-radius: 25px; }
      .detail-wrap .article-box .mobile-buttons .quantity-btn {
        display: flex;
        margin-top: 12px;
        align-items: center;
        width: auto;
        float: left;
        margin-bottom: 16px; }
      .detail-wrap .article-box .mobile-buttons .btns {
        float: left;
        margin-top: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between; }
    .detail-wrap .article-box .actions {
      width: 100%;
      float: left;
      display: flex;
      justify-content: space-between; }
      .detail-wrap .article-box .actions .add-review {
        max-width: 170px;
        display: flex;
        align-items: center; }
        .detail-wrap .article-box .actions .add-review .button {
          float: left;
          padding: 10px;
          margin-right: 13px; }
        .detail-wrap .article-box .actions .add-review p {
          margin-bottom: 0; }
      .detail-wrap .article-box .actions .ask-question {
        color: #36C4F4;
        font-size: 18px;
        text-decoration: underline;
        margin-top: auto; }

@media only screen and (max-width: 1200px) {
  .article-image {
    margin-bottom: 15px; } }

.article-image .preview {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 355px;
  border: 1px solid #D9EEF9;
  cursor: pointer;
  padding: 20px;
  background-color: #FFFFFF; }
  @media only screen and (max-width: 1200px) {
    .article-image .preview {
      height: 200px;
      padding: 0px;
      width: 70%;
      float: left; } }

@media only screen and (min-width: 767px) {
  .article-image ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: rem-calc(5);
    cursor: pointer; } }

.article-image .images {
  width: 100%;
  padding: 20px 0;
  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  cursor: grab;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/ }
  @media only screen and (max-width: 768px) {
    .article-image .images {
      margin-bottom: 0px;
      width: 25%;
      float: left;
      padding: 0;
      margin-left: 5%;
      max-height: 200px;
      padding-right: 10px; } }
  .article-image .images .image {
    display: inline-block;
    margin-right: 10px;
    width: 120px;
    height: 120px;
    position: relative;
    background-color: #FFFFFF;
    border: 1px solid #D9EEF9; }
    .article-image .images .image::after {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7); }
    .article-image .images .image > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px; }
      @media only screen and (max-width: 1200px) {
        .article-image .images .image > img {
          object-fit: contain; } }
      .article-image .images .image > img:last-child {
        margin-right: 0; }
    @media only screen and (max-width: 1200px) {
      .article-image .images .image {
        height: 60px;
        width: 100%;
        margin-bottom: 10px;
        display: block;
        padding: 3px; } }
  .article-image .images .active {
    position: relative;
    border: 1px solid #36C4F4; }
    .article-image .images .active::after {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent; }

.packages {
  margin: 0 -5px;
  margin-top: 12px;
  width: 100%;
  float: left;
  cursor: pointer; }
  .packages .package {
    background-color: #F3F3F3;
    float: left;
    padding: 10px 8px;
    margin: 0 5px;
    border: 2px solid #F3F3F3; }
    .packages .package p {
      margin-bottom: 0px;
      color: #999999;
      font-size: 16px; }
      .packages .package p:nth-child(2) {
        margin-bottom: 0; }
  .packages .active {
    border: 2px solid #36C4F4; }
    .packages .active p {
      margin-bottom: 0px;
      color: #13287E;
      font-size: 16px; }
      .packages .active p:nth-child(2) {
        margin-bottom: 0;
        font-weight: 600;
        color: #36C4F4; }

.article-detail {
  min-height: 500px; }

.about-product-title {
  margin-top: 24px; }
  .about-product-title h6 {
    color: #13287E;
    font-size: 26px;
    font-weight: 700;
    position: relative;
    padding-bottom: 7px;
    margin-bottom: 27px; }
    .about-product-title h6 span {
      color: #36C4F4; }

.detail-quantity {
  display: flex;
  align-items: center;
  border: 1px solid #D2D2D2;
  height: 47px;
  width: 100%;
  border-radius: 25px; }
  .detail-quantity h6 {
    padding: 10px 17px 10px 20px;
    min-width: 100px;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 1200px) {
      .detail-quantity h6 {
        width: 100%; } }
    .detail-quantity h6 span {
      padding-left: 22px;
      font-size: 22px; }
      .detail-quantity h6 span .inc {
        background-color: transparent;
        border: 0;
        outline: none; }
        .detail-quantity h6 span .inc::after {
          content: '';
          display: block;
          border-left: 1px solid #13287E;
          border-top: 1px solid #13287E;
          width: 11px;
          height: 11px;
          transform: rotate(135deg); }
      .detail-quantity h6 span .dec {
        background-color: transparent;
        border: 0;
        outline: none; }
        .detail-quantity h6 span .dec::after {
          content: '';
          display: block;
          border-left: 1px solid #13287E;
          border-top: 1px solid #13287E;
          width: 11px;
          height: 11px;
          transform: rotate(-45deg); }

.short-description {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 7px; }

.carousel-detail {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999; }
  .carousel-detail > button {
    outline: none;
    border: 0;
    background-color: transparent;
    position: fixed;
    top: 30px;
    right: 30px;
    color: #FFFFFF;
    font-size: 64px;
    z-index: 99999; }
    @media only screen and (max-width: 1200px) {
      .carousel-detail > button {
        top: 16px;
        right: 16px;
        font-size: 48px; } }
    .carousel-detail > button svg {
      fill: #FFFFFF; }
  .carousel-detail .carousel {
    width: 100%;
    height: 100%; }
    .carousel-detail .carousel .carousel-inner {
      height: 100%; }
    .carousel-detail .carousel .carousel-item {
      text-align: center;
      height: 100%; }
    .carousel-detail .carousel img {
      width: 50%;
      height: 80%;
      padding-top: 10%;
      object-fit: contain;
      object-position: center;
      margin: 0 auto; }
      @media only screen and (max-width: 1200px) {
        .carousel-detail .carousel img {
          width: 100%;
          height: 100%;
          padding-top: 0; } }
    .carousel-detail .carousel .carousel-control-prev {
      position: absolute;
      background-color: #FFFFFF;
      opacity: 1;
      left: 0;
      top: 50%;
      height: 60px;
      width: 40px;
      margin-top: -30px; }
      .carousel-detail .carousel .carousel-control-prev::after {
        content: ' ';
        border-left: 2px solid #13287E;
        border-top: 2px solid #13287E;
        transform: rotate(-45deg);
        width: 12px;
        height: 12px;
        position: absolute;
        top: 24px;
        left: 14px; }
    .carousel-detail .carousel .carousel-control-next {
      position: absolute;
      background-color: #FFFFFF;
      opacity: 1;
      right: 0;
      top: 50%;
      height: 60px;
      width: 40px;
      margin-top: -30px; }
      .carousel-detail .carousel .carousel-control-next::after {
        content: ' ';
        border-left: 2px solid #13287E;
        border-top: 2px solid #13287E;
        transform: rotate(135deg);
        width: 12px;
        height: 12px;
        position: absolute;
        top: 24px;
        left: 14px; }

.download-button {
  background-color: #13287E;
  border-radius: 27px;
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px; }

.app-detail-wrap {
  padding-top: 0; }
  @media only screen and (max-width: 1200px) {
    .app-detail-wrap {
      padding-top: 0; } }
  .app-detail-wrap h1 {
    font-size: 32px;
    font-weight: 600; }
  .app-detail-wrap .content-wrap {
    margin-top: 0; }
  .app-detail-wrap .section-articles {
    padding-top: 40px;
    padding-bottom: 120px; }
    .app-detail-wrap .section-articles .title {
      text-align: center;
      margin-bottom: 58px; }
      .app-detail-wrap .section-articles .title h2 {
        color: #13287E;
        font-size: 40px;
        font-weight: 900;
        margin-bottom: 0; }
        @media only screen and (max-width: 1200px) {
          .app-detail-wrap .section-articles .title h2 {
            font-size: 26px; } }
      .app-detail-wrap .section-articles .title p {
        font-size: 15px;
        color: #13287E;
        margin-bottom: 0; }
    .app-detail-wrap .section-articles a {
      text-decoration: none; }
  .app-detail-wrap .short-description-container {
    padding: 0; }
    .app-detail-wrap .short-description-container .button {
      font-size: 16px;
      font-weight: 600;
      color: #FFFFFF;
      border-radius: 0;
      background-color: #13287E;
      padding: 12px 18px 11px 18px;
      border-radius: 27px;
      width: 100%; }
    .app-detail-wrap .short-description-container .wishlist-btn {
      background-color: #FFF323;
      border: 0;
      float: right;
      outline: none;
      padding: 10px 10px;
      border-radius: 25px; }
  .app-detail-wrap .article-box {
    background-color: #FFFFFF;
    margin-bottom: 0;
    padding: 35px;
    padding-bottom: 26px; }
    @media only screen and (max-width: 768px) {
      .app-detail-wrap .article-box {
        padding: 15px;
        padding-bottom: 0; } }
    .app-detail-wrap .article-box ul {
      list-style: none; }
    .app-detail-wrap .article-box table {
      width: 100%;
      overflow-x: auto;
      max-width: 100%;
      border: 1px solid #36C4F4; }
      .app-detail-wrap .article-box table tr th {
        background-color: #36C4F4;
        padding: 16px;
        color: #FFFFFF; }
      .app-detail-wrap .article-box table tr td {
        border: 1px solid #36C4F4;
        padding: 16px; }
    .app-detail-wrap .article-box .variations-table {
      max-width: 100%;
      overflow: auto; }
    .app-detail-wrap .article-box .variations table tr th {
      font-size: 12px;
      padding: 6px; }
    .app-detail-wrap .article-box .variations table tr td {
      font-size: 12px;
      padding: 6px; }
    .app-detail-wrap .article-box h2 {
      font-size: 42px;
      font-weight: 600;
      display: flex;
      color: #222;
      justify-content: space-between; }
      @media only screen and (max-width: 768px) {
        .app-detail-wrap .article-box h2 {
          font-size: 24px; } }
    .app-detail-wrap .article-box h3 {
      font-size: 26px;
      font-weight: 400; }
      @media only screen and (max-width: 768px) {
        .app-detail-wrap .article-box h3 {
          font-size: 20px; } }
    .app-detail-wrap .article-box h4 {
      color: #C2CC50;
      font-weight: 700;
      font-size: 22px;
      margin-bottom: 4px; }
    .app-detail-wrap .article-box .stock {
      font-size: 18px;
      color: #13287E;
      font-weight: 300;
      display: flex;
      align-items: center;
      margin-bottom: 29px; }
      .app-detail-wrap .article-box .stock .out-of-stock {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #DE3636;
        float: left;
        margin-right: 14px; }
      .app-detail-wrap .article-box .stock .in-stock {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #00D20E;
        float: left;
        margin-right: 14px; }
    .app-detail-wrap .article-box .spacer {
      width: 100%;
      background-color: #CCCCCC;
      height: 1px;
      margin: 18px 0;
      float: left; }
    .app-detail-wrap .article-box .price {
      width: 100%;
      float: left;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      color: #36C4F4;
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 28px; }
      .app-detail-wrap .article-box .price span {
        color: #13287E;
        font-size: 36px;
        font-weight: 700;
        margin-top: auto; }
      .app-detail-wrap .article-box .price .old-price {
        color: #D2D2D2;
        font-size: 30px;
        font-weight: 300;
        text-decoration: line-through;
        margin-right: 27px; }
    .app-detail-wrap .article-box .buttons {
      float: left;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      align-items: center;
      display: none; }
      @media only screen and (min-width: 768px) {
        .app-detail-wrap .article-box .buttons {
          display: flex; } }
      .app-detail-wrap .article-box .buttons input {
        height: 35px;
        width: 60px;
        border-radius: 25px;
        border: 1px solid #D2D2D2;
        padding: 0 16px;
        margin-left: 15px; }
      @media only screen and (max-width: 768px) {
        .app-detail-wrap .article-box .buttons {
          margin-bottom: 0px; } }
      .app-detail-wrap .article-box .buttons .button {
        font-size: 16px;
        font-weight: 600;
        color: #FFFFFF;
        border-radius: 10px;
        background-color: #36C4F4;
        padding: 12px 18px 11px 18px; }
      .app-detail-wrap .article-box .buttons .button-black {
        background-color: #C2CC50; }
      .app-detail-wrap .article-box .buttons .wishlist-btn {
        background-color: #FFF323;
        border: 0;
        border-radius: 10px;
        outline: none;
        padding: 10px 18px; }
    .app-detail-wrap .article-box .actions {
      width: 100%;
      float: left;
      display: flex;
      justify-content: space-between; }
      .app-detail-wrap .article-box .actions .add-review {
        max-width: 170px;
        display: flex;
        align-items: center; }
        .app-detail-wrap .article-box .actions .add-review .button {
          float: left;
          padding: 10px;
          margin-right: 13px; }
        .app-detail-wrap .article-box .actions .add-review p {
          margin-bottom: 0; }
      .app-detail-wrap .article-box .actions .ask-question {
        color: #36C4F4;
        font-size: 18px;
        text-decoration: underline;
        margin-top: auto; }
  @media only screen and (max-width: 1200px) {
    .app-detail-wrap .article-image {
      margin-bottom: 15px; } }
  .app-detail-wrap .article-image img {
    height: 250px; }
  .app-detail-wrap .packages {
    margin: 0 -5px;
    margin-top: 12px;
    width: 100%;
    float: left;
    cursor: pointer; }
    .app-detail-wrap .packages .package {
      background-color: #F3F3F3;
      float: left;
      padding: 10px 8px;
      margin: 0 5px;
      border: 2px solid #F3F3F3; }
      .app-detail-wrap .packages .package p {
        margin-bottom: 0px;
        color: #999999;
        font-size: 16px; }
        .app-detail-wrap .packages .package p:nth-child(2) {
          margin-bottom: 0; }
    .app-detail-wrap .packages .active {
      border: 2px solid #36C4F4; }
      .app-detail-wrap .packages .active p {
        margin-bottom: 0px;
        color: #13287E;
        font-size: 16px; }
        .app-detail-wrap .packages .active p:nth-child(2) {
          margin-bottom: 0;
          font-weight: 600;
          color: #36C4F4; }
  .app-detail-wrap .article-detail {
    min-height: 500px; }
  .app-detail-wrap .about-product-title {
    margin-top: 24px; }
    .app-detail-wrap .about-product-title h6 {
      color: #13287E;
      font-size: 26px;
      font-weight: 700;
      position: relative;
      padding-bottom: 7px;
      margin-bottom: 27px; }
      .app-detail-wrap .about-product-title h6 span {
        color: #36C4F4; }
  .app-detail-wrap .detail-quantity {
    display: flex;
    align-items: center;
    border: 1px solid #D2D2D2;
    height: 47px;
    width: 100%;
    border-radius: 25px; }
    .app-detail-wrap .detail-quantity h6 {
      padding: 10px 17px 10px 20px;
      min-width: 100px;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
      width: 100%;
      justify-content: space-between;
      display: flex;
      align-items: center; }
      @media only screen and (max-width: 1200px) {
        .app-detail-wrap .detail-quantity h6 {
          width: 100%; } }
      .app-detail-wrap .detail-quantity h6 span {
        padding-left: 22px;
        font-size: 22px; }
        .app-detail-wrap .detail-quantity h6 span .inc {
          background-color: transparent;
          border: 0;
          outline: none; }
          .app-detail-wrap .detail-quantity h6 span .inc::after {
            content: '';
            display: block;
            border-left: 1px solid #13287E;
            border-top: 1px solid #13287E;
            width: 11px;
            height: 11px;
            transform: rotate(135deg); }
        .app-detail-wrap .detail-quantity h6 span .dec {
          background-color: transparent;
          border: 0;
          outline: none; }
          .app-detail-wrap .detail-quantity h6 span .dec::after {
            content: '';
            display: block;
            border-left: 1px solid #13287E;
            border-top: 1px solid #13287E;
            width: 11px;
            height: 11px;
            transform: rotate(-45deg); }
  .app-detail-wrap .short-description {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 7px; }
  .app-detail-wrap .carousel-detail {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999; }
    .app-detail-wrap .carousel-detail > button {
      outline: none;
      border: 0;
      background-color: transparent;
      position: fixed;
      top: 30px;
      right: 30px;
      color: #FFFFFF;
      font-size: 64px;
      z-index: 99999; }
      @media only screen and (max-width: 1200px) {
        .app-detail-wrap .carousel-detail > button {
          top: 16px;
          right: 16px;
          font-size: 48px; } }
      .app-detail-wrap .carousel-detail > button svg {
        fill: #FFFFFF; }
    .app-detail-wrap .carousel-detail .carousel {
      width: 100%;
      height: 100%; }
      .app-detail-wrap .carousel-detail .carousel .carousel-inner {
        height: 100%; }
      .app-detail-wrap .carousel-detail .carousel .carousel-item {
        text-align: center;
        height: 100%; }
      .app-detail-wrap .carousel-detail .carousel img {
        width: 50%;
        height: 80%;
        padding-top: 10%;
        object-fit: contain;
        object-position: center;
        margin: 0 auto; }
        @media only screen and (max-width: 1200px) {
          .app-detail-wrap .carousel-detail .carousel img {
            width: 100%;
            height: 100%;
            padding-top: 0; } }
      .app-detail-wrap .carousel-detail .carousel .carousel-control-prev {
        position: absolute;
        background-color: #FFFFFF;
        opacity: 1;
        left: 0;
        top: 50%;
        height: 60px;
        width: 40px;
        margin-top: -30px; }
        .app-detail-wrap .carousel-detail .carousel .carousel-control-prev::after {
          content: ' ';
          border-left: 2px solid #13287E;
          border-top: 2px solid #13287E;
          transform: rotate(-45deg);
          width: 12px;
          height: 12px;
          position: absolute;
          top: 24px;
          left: 14px; }
      .app-detail-wrap .carousel-detail .carousel .carousel-control-next {
        position: absolute;
        background-color: #FFFFFF;
        opacity: 1;
        right: 0;
        top: 50%;
        height: 60px;
        width: 40px;
        margin-top: -30px; }
        .app-detail-wrap .carousel-detail .carousel .carousel-control-next::after {
          content: ' ';
          border-left: 2px solid #13287E;
          border-top: 2px solid #13287E;
          transform: rotate(135deg);
          width: 12px;
          height: 12px;
          position: absolute;
          top: 24px;
          left: 14px; }
  .app-detail-wrap .download-button {
    background-color: #13287E;
    border-radius: 27px;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px; }
  .app-detail-wrap .mobile-buttons {
    float: left;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #FFFFFF;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: space-between; }
    .app-detail-wrap .mobile-buttons .button {
      font-size: 16px;
      font-weight: 600;
      color: #FFFFFF;
      border-radius: 0;
      background-color: #13287E;
      padding: 12px 18px 11px 18px;
      border-radius: 27px; }
    .app-detail-wrap .mobile-buttons .button-black {
      background-color: #36C4F4;
      margin-left: 20px;
      border-radius: 27px; }
    .app-detail-wrap .mobile-buttons .wishlist-btn {
      background-color: #FFF323;
      border: 0;
      outline: none;
      padding: 10px 10px;
      margin-left: 65px;
      border-radius: 25px; }
    .app-detail-wrap .mobile-buttons .quantity-btn {
      display: flex;
      align-items: center;
      width: auto;
      float: left; }
    .app-detail-wrap .mobile-buttons .btns {
      float: left;
      margin-top: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }

.account-wrap {
  padding-top: 145px; }
  @media only screen and (max-width: 1200px) {
    .account-wrap {
      padding-top: 70px; } }
  .account-wrap h1 {
    font-size: 32px;
    font-weight: 600; }
  .account-wrap .content-wrap {
    margin-top: 29px; }
  .account-wrap .account-info p {
    font-size: 18px; }
  .account-wrap .box-container {
    background-color: #FFFFFF;
    /*-webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);*/
    margin-bottom: 30px;
    padding: 10px 0;
    padding-bottom: 26px;
    margin-top: 10px; }
    @media only screen and (max-width: 768px) {
      .account-wrap .box-container {
        padding: 0px;
        padding-bottom: 26px; } }
    .account-wrap .box-container h3 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 24px; }
    .account-wrap .box-container h6 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 24px; }
    .account-wrap .box-container .lost-password {
      color: #13287E; }
    .account-wrap .box-container table {
      width: 100%;
      border: 1px solid #36C4F4; }
      .account-wrap .box-container table tr th {
        background-color: #36C4F4;
        padding: 16px;
        color: #FFFFFF; }
      .account-wrap .box-container table tr td {
        border: 1px solid #36C4F4;
        padding: 16px; }
    .account-wrap .box-container .button-group {
      padding: 0 0 20px 0; }
      @media only screen and (max-width: 991px) {
        .account-wrap .box-container .button-group {
          padding: 0 15px; } }
      .account-wrap .box-container .button-group .button {
        margin-right: 20px; }
        @media only screen and (max-width: 991px) {
          .account-wrap .box-container .button-group .button {
            margin-right: 0;
            margin-bottom: 16px;
            width: 100%; } }
    .account-wrap .box-container .table {
      border: 1px solid #D2D2D2; }
      @media only screen and (max-width: 991px) {
        .account-wrap .box-container .table {
          margin: 0 15px;
          margin-bottom: 16px; } }
      .account-wrap .box-container .table .row > div {
        padding: 15px; }
        .account-wrap .box-container .table .row > div P {
          margin-bottom: 0;
          font-size: 18px; }
      .account-wrap .box-container .table .header {
        background-color: #36C4F4;
        color: #FFFFFF;
        font-weight: 600;
        padding: 0;
        height: 34px; }
        .account-wrap .box-container .table .header > div {
          padding: 0 15px;
          display: flex;
          align-items: center;
          font-size: 14px; }
      .account-wrap .box-container .table .border-row {
        border-bottom: 1px solid #D2D2D2; }
        .account-wrap .box-container .table .border-row:last-child {
          border-bottom: 0; }
        .account-wrap .box-container .table .border-row > div {
          border-right: 1px solid #D2D2D2; }
          .account-wrap .box-container .table .border-row > div:last-child {
            border-right: 0; }
    .account-wrap .box-container .spacer {
      width: 100%;
      background-color: #CCCCCC;
      height: 1px;
      margin: 18px 0;
      float: left; }
    .account-wrap .box-container a {
      text-decoration: none !important; }
      .account-wrap .box-container a .box {
        border: 2px solid #D9EEF9;
        border-radius: 6px;
        padding: 20px 40px;
        text-align: center;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 20px;
        text-decoration: none !important; }
        .account-wrap .box-container a .box p {
          margin-top: 15px;
          margin-bottom: 0;
          color: #13287E;
          font-size: 18px;
          line-height: 1;
          font-weight: 500;
          text-decoration: none !important; }

.cart-wrap .table {
  border: 1px solid #D2D2D2; }
  @media only screen and (max-width: 991px) {
    .cart-wrap .table {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 16px; } }

.cart-wrap .cart-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0; }
  .cart-wrap .cart-progress > li {
    position: relative;
    text-align: center; }
    .cart-wrap .cart-progress > li::before {
      content: " ";
      position: absolute;
      display: block;
      height: 6px;
      width: 98px;
      background-color: #CCCCCC;
      border-radius: 13px;
      left: 200px;
      margin-left: -15px;
      top: 21px; }
      @media only screen and (max-width: 1200px) {
        .cart-wrap .cart-progress > li::before {
          left: 150%;
          width: 30px; } }
      @media only screen and (max-width: 768px) {
        .cart-wrap .cart-progress > li::before {
          display: none; } }
      @media only screen and (min-width: 768px) and (max-width: 1112px) {
        .cart-wrap .cart-progress > li::before {
          width: 70px; } }
    .cart-wrap .cart-progress > li::after {
      content: " ";
      position: absolute;
      height: 6px;
      width: 0;
      background-color: #36C4F4;
      border-radius: 13px;
      left: 200px;
      margin-left: -15px;
      top: 21px;
      -webkit-transition: width 0.5s;
      /* For Safari 3.1 to 6.0 */
      transition: width 0.5s; }
      @media only screen and (max-width: 1200px) {
        .cart-wrap .cart-progress > li::after {
          left: 150%; } }
      @media only screen and (max-width: 768px) {
        .cart-wrap .cart-progress > li::after {
          display: none; } }
    .cart-wrap .cart-progress > li:last-child::before {
      display: none; }
    .cart-wrap .cart-progress > li:last-child::after {
      display: none; }
    .cart-wrap .cart-progress > li .icon {
      margin: 0 auto;
      margin-bottom: 16px;
      border: 4px solid #CCCCCC;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .cart-wrap .cart-progress > li .icon svg {
        fill: #36C4F4;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px; }
    .cart-wrap .cart-progress > li:first-child svg path {
      stroke: #FFFFFF; }
    .cart-wrap .cart-progress > li p {
      max-width: 140px;
      text-align: center;
      margin-bottom: 0;
      font-size: 18px; }
      @media only screen and (max-width: 420px) {
        .cart-wrap .cart-progress > li p {
          font-size: 14px; } }
      .cart-wrap .cart-progress > li p:nth-child(2) {
        font-size: 18px;
        font-weight: 600; }
        @media only screen and (max-width: 420px) {
          .cart-wrap .cart-progress > li p:nth-child(2) {
            font-size: 14px; } }
      @media only screen and (max-width: 1200px) {
        .cart-wrap .cart-progress > li p:nth-child(3) {
          display: none; } }
    .cart-wrap .cart-progress > li:nth-child(1) svg {
      fill: #36C4F4; }
  .cart-wrap .cart-progress .active::after {
    width: 49px; }
    @media only screen and (min-width: 768px) and (max-width: 1112px) {
      .cart-wrap .cart-progress .active::after {
        width: 35px; } }
    @media only screen and (max-width: 1200px) {
      .cart-wrap .cart-progress .active::after {
        width: 15px; } }
  .cart-wrap .cart-progress .active .icon {
    background-color: #36C4F4;
    border: 0; }
  .cart-wrap .cart-progress .active:nth-child(1) svg {
    stroke: #FFFFFF; }
  .cart-wrap .cart-progress .active:nth-child(4) svg {
    fill: #FFFFFF;
    stroke: unset; }
  .cart-wrap .cart-progress .active:last-child svg path {
    fill: #FFFFFF; }
  .cart-wrap .cart-progress .done::after {
    width: 98px;
    display: block; }
    @media only screen and (min-width: 768px) and (max-width: 1112px) {
      .cart-wrap .cart-progress .done::after {
        width: 70px; } }
    @media only screen and (max-width: 1200px) {
      .cart-wrap .cart-progress .done::after {
        width: 30px; } }
  .cart-wrap .cart-progress .done .icon {
    background-color: #36C4F4;
    border: 0; }
  .cart-wrap .cart-progress .done:nth-child(1) svg {
    stroke: #FFFFFF; }
  .cart-wrap .cart-progress .done:nth-child(4) svg {
    fill: #FFFFFF;
    stroke: unset; }

@media only screen and (max-width: 991px) {
  .cart-wrap .button-group {
    padding: 0 !important; } }

.cart-wrap .back {
  display: flex;
  align-items: center;
  color: #13287E;
  font-size: 16px;
  height: 40px; }
  .cart-wrap .back::before {
    content: " ";
    display: block;
    border-bottom: 2px solid #36C4F4;
    border-right: 2px solid #36C4F4;
    transform: rotate(135deg);
    width: 8px;
    height: 8px;
    margin-right: 6px; }

.cart-wrap .checkout-button {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end; }
  .cart-wrap .checkout-button .button {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    .cart-wrap .checkout-button .button::after {
      content: " ";
      display: block;
      border-bottom: 2px solid #FFFFFF;
      border-right: 2px solid #FFFFFF;
      transform: rotate(-45deg);
      width: 8px;
      height: 8px;
      float: right;
      margin-left: 8px; }

.cart-wrap .checkout-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px; }
  .cart-wrap .checkout-buttons span {
    padding: 0 16px; }
  .cart-wrap .checkout-buttons .button {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    .cart-wrap .checkout-buttons .button::after {
      content: " ";
      display: block;
      border-bottom: 2px solid #FFFFFF;
      border-right: 2px solid #FFFFFF;
      transform: rotate(-45deg);
      width: 8px;
      height: 8px;
      float: right;
      margin-left: 8px; }

.cart-wrap .notes-input .input-wrap {
  margin-bottom: 0; }
  .cart-wrap .notes-input .input-wrap input[type=text] {
    height: 36px; }

.cart-wrap .coupon-input .input-wrap {
  margin-bottom: 0;
  margin-bottom: 32px; }
  .cart-wrap .coupon-input .input-wrap input[type=text] {
    height: 36px;
    width: 120px; }

.cart-wrap .cart-table {
  border: 1px solid #D2D2D2;
  border-bottom: 0;
  margin-bottom: 24px; }
  .cart-wrap .cart-table .header {
    background-color: #13287E;
    height: 34px;
    display: flex;
    align-items: center; }
    .cart-wrap .cart-table .header > div {
      color: #FFFFFF;
      font-weight: 500; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .header > div {
          font-size: 12px; } }
    .cart-wrap .cart-table .header .number {
      text-align: right; }
  .cart-wrap .cart-table .article-info {
    display: flex;
    align-items: center;
    font-size: 14px; }
    .cart-wrap .cart-table .article-info > div {
      padding: 12px 8px;
      border-right: 1px solid #D2D2D2;
      border-bottom: 1px solid #D2D2D2;
      height: 60px;
      display: flex;
      align-items: center; }
      .cart-wrap .cart-table .article-info > div:last-child {
        border-right: 0; }
    .cart-wrap .cart-table .article-info .select-field {
      width: 100%; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .article-info .select-field {
          font-size: 10px; }
          .cart-wrap .cart-table .article-info .select-field .dropdown-toggle::before {
            margin-top: 2px; } }
    @media only screen and (max-width: 991px) {
      .cart-wrap .cart-table .article-info {
        font-size: 12px; } }
    .cart-wrap .cart-table .article-info input[type=text] {
      height: 36px;
      width: 50px;
      border-radius: 6px;
      border: 1px solid #D2D2D2;
      padding: 0 16px;
      margin-right: 8px; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .article-info input[type=text] {
          width: 30px;
          padding: 0 8px; } }
    .cart-wrap .cart-table .article-info .number {
      justify-content: flex-end;
      padding-right: 12px; }
    .cart-wrap .cart-table .article-info .delete {
      justify-content: center; }
    .cart-wrap .cart-table .article-info label {
      margin-bottom: 0; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .article-info label {
          display: none; } }
    .cart-wrap .cart-table .article-info img {
      width: 44px;
      height: 33px;
      margin-right: 12px; }
  .cart-wrap .cart-table .payment-info {
    display: flex;
    align-items: center;
    font-size: 14px; }
    .cart-wrap .cart-table .payment-info > div {
      padding: 12px 8px;
      border-right: 1px solid #D2D2D2;
      border-bottom: 1px solid #D2D2D2;
      height: 500px; }
      .cart-wrap .cart-table .payment-info > div:last-child {
        border-right: 0; }
    .cart-wrap .cart-table .payment-info .select-field {
      width: 100%; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .payment-info .select-field {
          font-size: 10px; } }
    @media only screen and (max-width: 991px) {
      .cart-wrap .cart-table .payment-info {
        font-size: 12px; } }
    .cart-wrap .cart-table .payment-info .number {
      display: flex;
      justify-content: flex-end;
      padding-right: 12px; }
    .cart-wrap .cart-table .payment-info .delete {
      display: flex;
      justify-content: center; }
    .cart-wrap .cart-table .payment-info label {
      margin-bottom: 0; }
    .cart-wrap .cart-table .payment-info img {
      width: 44px;
      height: 33px;
      margin-right: 12px; }
